import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

import { Navigate } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import AppConfig from '../services/app-config';
import BackgroundImage from '../components/Cityline';
import App from './Activity';

import { useSwipeable } from 'react-swipeable';
import { motion, AnimatePresence } from 'framer-motion';

import EventBusyIcon from '@mui/icons-material/EventBusy';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LockIcon from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/Key';
import BlockIcon from '@mui/icons-material/Block';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Checkbox from '@mui/material/Checkbox';

const apiUrl = process.env.REACT_APP_MGO_API_URL;
const publicUrl = process.env.REACT_APP_PUBLIC_URL;

export default function UsersAdmin({setNavbarState, navbarState, window}) {
  const [users, setUsers] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [tabValue, setTabValue] = React.useState('activo');
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' }); 
  const [newUserName, setNewUserName] = React.useState('');
  const [newUserLastName, setNewUserLastName] = React.useState('');
  const [newUserMode, setNewUserMode] = React.useState('pro');
  const [newUnitMode, setNewUnitMode] = React.useState('propia');
  const [newUserUnit, setNewUserUnit] = React.useState('');
  const [newUserUnitValid,setNewUserUnitValid] = React.useState({"error": false, "message": ""});
  const [newUserRole, setNewUserRole] = React.useState('habitante');
  const [newUserPhone, setNewUserPhone] = React.useState('');
  const [newUserEmail, setNewUserEmail] = React.useState('');
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
  const [drawerShow, setDrawerShow] = React.useState(false);
  const [sendInvite, setSendInvite] = React.useState(true);
  const [deleteUserDialog, setDeleteUserDialog] = React.useState(false);
  const [pwdResetDialog, setPwdResetDialog] = React.useState(false);
  const [userToDelete, setUserToDelete] = React.useState(null);
  const [userToReset, setUserToReset] = React.useState(null);
  const [modules, setModules] = React.useState({});
  const [showUnitMode, setShowUnitMode] = React.useState(false);
  const [drawerCTA, setDrawerCTA] = React.useState('Agregar');
  const [drawerStep, setDrawerStep] = React.useState(1);
  const [swipeDirection, setSwipeDirection] = React.useState(null);
  const [moreOptsDrawer, setMoreOptsDrawer] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [accessRestrictModal, setAccessRestrictModal] = React.useState(false);
  const [selectedModules, setSelectedModules] = React.useState({});

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchValue.toLowerCase())
    || user.lastname.toLowerCase().includes(searchValue.toLowerCase())
    || user.phone.toLowerCase().includes(searchValue.toLowerCase())
    || user.unit.toLowerCase().includes(searchValue.toLowerCase())
    || user.role.toLowerCase().includes(searchValue.toLowerCase())
    || user.username.toLowerCase().includes(searchValue.toLowerCase())
  );

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const handleDrawerToggle = () => {
    setDrawerShow((prevState) => !prevState);
    if (!drawerShow) {
      resetDrawer();
    }
  };

  const roleDict = {
    "Habitante": "habitante",
    "Receptor": "receptor",
    "Admin": "admin"
  }

  var regex = null
  if (AppConfig.getLocal("unit_regex")) {
    regex = new RegExp(AppConfig.getLocal("unit_regex"));
  }

  const handleModeChange = (value) => {
    setNewUserMode(value);
    if (value === "inq") {
      setNewUnitMode("rentada");
    }
  }

  const handleUnitChange = (event) => {
    if (AppConfig.getLocal("unit_regex") && newUserRole === 'habitante') {
      var text = event.target.value;
      if (AppConfig.getLocal("unit_sample")) {
        var complement_length = AppConfig.getLocal("unit_sample").length - text.length ;
        var complement_text = AppConfig.getLocal("unit_sample").slice(-complement_length);
        if (complement_length === 0) {
          complement_text = "" ;
          setShowUnitMode(true);
        }
        text = `${text}${complement_text}`
      }
      if (!regex.test(text)) {
        setNewUserUnitValid({"error": true, "message": `Formato Incorrecto ej. ${AppConfig.getLocal("unit_sample")}`});
        setShowUnitMode(false);
      } else {
        setNewUserUnitValid({"error": false, "message": ""});
      }
    } 
    setNewUserUnit(event.target.value);
  }

  const handleNewUser = () => {
    setNewUserName("");
    setNewUserLastName("");
    setNewUserPhone("");
    setNewUserEmail("");
    setNewUserRole("habitante");
    setNewUserUnit("");
    setDrawerCTA('Agregar');
    setSendInvite(true);
    setDrawerShow(true);
    setNewUserMode('pro');
    setNewUnitMode('propia');
    setShowUnitMode(false);
    setDrawerStep(1);
  }

  const handleEditUser = (username) => {
    const user = users.find(user => user.username === username);
    setNewUserName(user.name);
    setNewUserLastName(user.lastname);
    setNewUserPhone(user.phone);
    setNewUserEmail(user.username);
    setNewUnitMode(user.unit_data.mode);
    setNewUserRole(roleDict[user.role]);
    setNewUserUnit(user.unit);
    setDrawerCTA('Editar');
    setSendInvite(false);
    setDrawerShow(true);
    setNewUserMode(user.mode);
    setShowUnitMode(true);
    setDrawerStep(1);
  }

  const fetchUsers = (type='activo') => {
    if (type === "activo"){
        axios.get(`${apiUrl}/users/allusersadmin`)
         .then((response) => {
            AppConfig.parseResponse(response);
           if (response.status === 200) {
             setUsers(response.data.data)
             setLoading(false)
           }
         })
         .catch(error => {
           console.error(error);
           if (error.response.data.code === 401) {
              setRedirect({ trigger: true, route: '/login' })
            }
         })} else {
        axios.get(`${apiUrl}/users/allinvitedadmin`)
        .then((response) => {
            AppConfig.parseResponse(response);
            if (response.status === 200) {
            setUsers(response.data.data)
            setLoading(false)
            }
        })
        .catch(error => {
            console.error(error);
            if (error.response.data.code === 401) {
              setRedirect({ trigger: true, route: '/login' })
            }
        })

         }
  }

  const fetchAvailableModules = () => {
    axios.get(`${apiUrl}/modules/available`)
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setModules(response.data.data)
        }
      })
  }

  const createNewUser = () => {
    var localNewUserUnit;
    if(!['habitante', 'receptor', 'admin'].includes(newUserRole)){
      setSnackbarSeverity("warning")
      setSnackbarMessage('El rol solo puede ser admin, receptor o habitante');
      setSnackbarOpen(true);
      return;
    }
    if (newUserName === ""){
      setSnackbarSeverity("warning")
      setSnackbarMessage('Porfavor ingresa el nombre del usuario.');
      setSnackbarOpen(true);
      return;
    }
    if (newUserLastName === ""){
      setSnackbarSeverity("warning")
      setSnackbarMessage('Porfavor ingresa el apellido del usuario.');
      setSnackbarOpen(true);
      return;
    }
    if (newUserPhone === ""){
      setSnackbarSeverity("warning")
      setSnackbarMessage('Porfavor ingresa el numero de telefono del usuario.');
      setSnackbarOpen(true);
      return;
    }
    if (newUserEmail === ""){
      setSnackbarSeverity("warning")
      setSnackbarMessage('Porfavor ingresa el correo del usuario.');
      setSnackbarOpen(true);
      return;
    }
    if (newUserRole === ""){
      setSnackbarSeverity("warning")
      setSnackbarMessage('Porfavor ingresa el nombre del usuario.');
      setSnackbarOpen(true);
    } else if (newUserRole === 'receptor')  {
      localNewUserUnit = 'Recepcion'
    } else if (newUserRole === 'admin')  {
      localNewUserUnit = 'Administracion'
    } else if (newUserRole === 'habitante')  {
      localNewUserUnit = newUserUnit
    }

    if (localNewUserUnit === ""){
      setSnackbarSeverity("warning")
      setSnackbarMessage('Porfavor ingresa el la unidad del usuario.');
      setSnackbarOpen(true);
      return;
    }

    if (newUserMode === "" || newUserMode === "ninguna" && newUserRole === 'habitante'){
      setSnackbarSeverity("warning")
      setSnackbarMessage('Porfavor ingresa la modalidad del usuario.');
      setSnackbarOpen(true);
      return;
    }

    if (AppConfig.getLocal("unit_regex") && newUserRole === 'habitante') {
      if (!regex.test(localNewUserUnit)) {
        setSnackbarSeverity("warning")
        setSnackbarMessage(`La unidad no cumple con el formato esperado de apartamentos, ej. ${AppConfig.getLocal("unit_sample")}`);
        setSnackbarOpen(true);
        return;
      }
    }

    const data = {
      name: newUserName,
      lastname: newUserLastName,
      unit: localNewUserUnit,
      role: newUserRole,
      phone: newUserPhone,
      email: newUserEmail,
      sendMail: sendInvite,
      action: drawerCTA.toLowerCase(),
      mode: newUserMode,
      unitMode: newUnitMode
    }

    axios.post(`${apiUrl}/invite/user`, data)
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbarSeverity("success")
          if (drawerCTA === "Agregar"){
            setSnackbarMessage('Usuario creado exitosamente.');
            setTabValue('invitado');
            fetchUsers('invitado');
          } else if (drawerCTA === "Editar"){
            setSnackbarMessage('Usuario editado exitosamente.');
            setTabValue('activo');
            fetchUsers('activo');
          }
          setDrawerShow(false);
          setSnackbarOpen(true);
        }
      })
      .catch((err) => {
        if (err.response.data.code === 409) {
          setSnackbarSeverity("error")
          setSnackbarMessage('El usuario ya existe en la base de datos.');
          setSnackbarOpen(true);
        } else {
        console.error(err);
        setSnackbarSeverity("error")
        setSnackbarMessage('Tuvimos un error intenta de nuevo en otro momento.');
        setSnackbarOpen(true);}
      });
  };

  const handleSendInviteSwitch = (event) => {
    setSendInvite(event.target.checked);
  };

  const handleNextStep = () => {
    setDrawerStep(2);
  };

  const handlePreviousStep = () => {
    setDrawerStep(1);
  };

  const resetDrawer = () => {
    setDrawerStep(1);
    setNewUserName("");
    setNewUserLastName("");
    setNewUserPhone("");
    setNewUserEmail("");
    setNewUserRole("habitante");
    setNewUserUnit("");
    setNewUserMode('pro');
    setNewUnitMode('propia');
    setShowUnitMode(false);
  };

  const newUserDrawer = (
    <Card
      sx={{ py: 3, px: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto', borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", maxWidth: "550px" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ mb: 1 }}>
          {drawerCTA} Usuario
        </Typography>
        {newUserRole === 'habitante' && (
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 2 }}>
            Paso {drawerStep} de 2
          </Typography>
        )}
        {newUserRole !== 'habitante' || drawerStep === 1 ? (
          <>
            <TextField sx={{ mb: 2}} fullWidth size="medium" value={newUserName} id='name' label="Nombre" variant="outlined" onChange={(e) => setNewUserName(e.target.value)} />
            <TextField sx={{ mb: 2}} fullWidth size="medium" value={newUserLastName} id='lastname' label="Apellido" variant="outlined" onChange={(e) => setNewUserLastName(e.target.value)} />
            <TextField sx={{ mb: 2}} fullWidth size="medium" value={newUserPhone} id='phone' label="Telefono" variant="outlined" onChange={(e) => setNewUserPhone(e.target.value)} />
            {drawerCTA === "Editar" ? (
              <TextField
                sx={{ mb: 2}}
                fullWidth
                size="medium"
                value={newUserEmail}
                id='email'
                label="Correo"
                variant="outlined"
                disabled
                onChange={(e) => setNewUserEmail(e.target.value)}
              />
            ) : (
              <TextField
                sx={{ mb: 2}}
                fullWidth
                size="medium"
                value={newUserEmail}
                id='email'
                label="Correo"
                variant="outlined"
                onChange={(e) => setNewUserEmail(e.target.value)}
              />
            )}
            <FormControl sx={{ mb: 2, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">Rol</InputLabel>
              <Select
                labelId="role-select"
                id="role-select"
                value={newUserRole}
                label="Rol"
                onChange={(e) => setNewUserRole(e.target.value)}
              >
                <MenuItem value={"habitante"}>Habitante</MenuItem>
                <MenuItem value={"receptor"}>Receptor</MenuItem>
                <MenuItem value={"admin"}>Administrador</MenuItem>
              </Select>
            </FormControl>
          </>
        ) : (
          <>
            <TextField sx={{ mb: 1}}
              fullWidth size="medium"
              value={newUserUnit}
              id='unit'
              label={AppConfig.get('unit_type')}
              variant="outlined"
              onChange={(e) => handleUnitChange(e)} 
              helperText={newUserUnitValid.message}
              error={newUserUnitValid.error}
            />
            <FormControl sx={{ my: 2 }} fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Modalidad</InputLabel>
              <Select
                labelId="role-select"
                id="role-select"
                value={newUserMode}
                label="Modalidad"
                onChange={(e) => handleModeChange(e.target.value)}
              >
                <MenuItem value={"pro"}>Propietario</MenuItem>
                <MenuItem value={"inq"}>Inquilino</MenuItem>
                {drawerCTA !== "Agregar" && <MenuItem value={"ninguna"}>Sin Configurar</MenuItem>}
              </Select>
            </FormControl>
            {showUnitMode && (
              <FormControl sx={{ my: 2 }} fullWidth>
                <InputLabel id="unit-usage-label" shrink>{`Uso de ${newUserUnit}`}</InputLabel>
                <Select
                  labelId="unit-usage-label"
                  id="unit-usage-select"
                  value={newUnitMode}
                  label={`Uso de ${newUserUnit}`}
                  onChange={(e) => setNewUnitMode(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="ninguna" disabled>Seleccione una opción</MenuItem>
                  <MenuItem value="rentada">En Renta</MenuItem>
                  <MenuItem value="propia" disabled={newUserMode === 'inq'}>Uso Propio</MenuItem>
                  <MenuItem value="airbnb" disabled={newUserMode === 'inq'}>AirBnb</MenuItem>
                </Select>
              </FormControl>
            )}
          </>
        )}
        {drawerCTA === "Agregar" && drawerStep === 1 && (
          <FormGroup>
            <FormControlLabel control={<Switch onChange={handleSendInviteSwitch} defaultChecked/>} sx={{ mb: 1 }} label="Enviar Correo de Invitacion" />
          </FormGroup>
        )}
      </CardContent>
      <CardActions>
        {newUserRole === 'habitante' ? (
          drawerStep === 1 ? (
            <>
              <Button size="medium" onClick={handleDrawerToggle} color="inherit" variant="outlined">Cancelar</Button>
              <Button size="medium" onClick={handleNextStep} color="secondary" variant="contained">Siguiente</Button>
            </>
          ) : (
            <>
              <Button size="medium" onClick={handlePreviousStep} color="inherit" variant="outlined">Atrás</Button>
              <Button size="medium" onClick={createNewUser} color="secondary" variant="contained">{drawerCTA}</Button>
            </>
          )
        ) : (
          <>
            <Button size="medium" onClick={handleDrawerToggle} color="inherit" variant="outlined">Cancelar</Button>
            <Button size="medium" onClick={createNewUser} color="secondary" variant="contained">{drawerCTA}</Button>
          </>
        )}
      </CardActions>
    </Card>
  );

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (tabValue === 'activo') {
        setSwipeDirection('left');
        setTabValue('invitado');
        fetchUsers('invitado');
      }
    },
    onSwipedRight: () => {
      if (tabValue === 'invitado') {
        setSwipeDirection('right');
        setTabValue('activo');
        fetchUsers('activo');
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const pageVariants = {
    enter: (direction) => {
      return {
        x: direction === 'left' ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        x: direction === 'left' ? -1000 : 1000,
        opacity: 0
      };
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  React.useEffect(() => {
    fetchUsers(tabValue)
    fetchAvailableModules()
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
  }, [])

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const closeDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  }

  const showDeleteUserDialog = (user) => {
    user['user_id'] = user.invcode ? user.invcode : user.username;
    setUserToDelete(user);
    setDeleteUserDialog(true);
  }

  const showPwdResetDialog = (user) => {
    setUserToReset(user);
    setPwdResetDialog(true);
  }

  const closePwdResetDialog = () => {
    setPwdResetDialog(false);
  }
  
  
  const deleteUser = (userId) => {
    setLoading(true)
    setDeleteUserDialog(false);
    axios.post(`${apiUrl}/delete/user`, {"id": userId})
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbarSeverity("success")
          setSnackbarMessage('Usuario eliminado.');
          fetchUsers(tabValue);          
          setSnackbarOpen(true);
          setLoading(false)
          setMoreOptsDrawer(false);
          setSelectedUser(null);
        }
      })
      .catch((err) => {
        console.error(err);
        setSnackbarSeverity("error")
        setSnackbarMessage('Tuvimos un error intenta de nuevo en otro momento.');
        setSnackbarOpen(true);
      });
  };

  const editUser = () => {
    // Implement the method to navigate to edit user
  }

  const shareInvite = (invcode) => {
    if (navigator.share) {
      navigator.share({
         title: 'Registrate en MiGarita.Online',
         url: `${publicUrl}/registro?invcode=${invcode}`
      }).then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      console.log('Share not supported on this browser, do it manually');
    }
  }

  const resendInvite = (invcode) => {
    setLoading(true)
    axios.post(`${apiUrl}/invite/resend`, {"invite_code": invcode})
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbarSeverity("success")
          setSnackbarMessage('Correo de invitacion enviado.');
          setSnackbarOpen(true);
          setLoading(false)
        }
      })
      .catch((err) => {
        console.error(err);
        setSnackbarSeverity("error")
        setSnackbarMessage('Tuvimos un error intenta de nuevo en otro momento.');
        setSnackbarOpen(true);
        setLoading(false)
      });
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setLoading(true)
    fetchUsers(newValue);
  };

  const handleMoreOptions = (user) => {
    setSelectedUser(user);
    setMoreOptsDrawer(true);
  };

  const handleCloseMoreOpts = () => {
    setMoreOptsDrawer(false);
    setSelectedUser(null);
  };

  const handleModuleChange = (event) => {
    setSelectedModules({
      ...selectedModules,
      [event.target.name]: event.target.checked,
    });
  };

  const openAccessRestrictModal = (user) => {
    let modulesToRestrict = {}
    // Check if user.restricted_modules is an array
    if (Array.isArray(user.restricted_modules)) {
      // If it's an array, set each module to true
      user.restricted_modules.forEach(module => {
        modulesToRestrict[module] = true;
      });
    } else if (typeof user.restricted_modules === 'object') {
      // If it's already an object, use it directly
      modulesToRestrict = { ...user.restricted_modules };
    }
    
    setSelectedUser(user);
    setSelectedModules(modulesToRestrict);
    setAccessRestrictModal(true);
  };

  const closeAccessRestrictModal = () => {
    setAccessRestrictModal(false);
    setSelectedUser(null);
  };

  const saveAccessRestrictions = () => {
    console.log('Saving access restrictions for user:', selectedUser);
    console.log('Restricted modules:', selectedModules);
    axios.post(`${apiUrl}/modules/restrict`, {
      "username": selectedUser.username,
      "modules": selectedModules
    })
    .then((response) => {
      AppConfig.parseResponse(response);
      if (response.status === 200) {
        setSnackbarSeverity("success");
        setSnackbarMessage('Accesos actualizados exitosamente.');
        setSnackbarOpen(true);
        closeAccessRestrictModal();
        setMoreOptsDrawer(false);
        fetchUsers(tabValue);
      }
    })
    .catch((err) => {
      console.error(err);
      setSnackbarSeverity("error");
      setSnackbarMessage('Error al actualizar los accesos.');
      setSnackbarOpen(true);
    });
  };

  const moreOptsDrawerContent = (
    <Card
    sx={{ 
      py: 3, 
      px: 2, 
      display: 'block', 
      marginLeft: 'auto', 
      marginRight: 'auto', 
      borderTopLeftRadius: "20px", 
      borderTopRightRadius: "20px", 
      borderBottomRightRadius: "0px", 
      borderBottomLeftRadius: "0px", 
      maxWidth: "550px",
      width: '100%' 
    }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ mb: 2 }}>
          Mas Opciones para {selectedUser ? `${selectedUser.name} ${selectedUser.lastname}` : ''}
        </Typography>
        <List>
          <ListItem button onClick={() => openAccessRestrictModal(selectedUser)}>
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary="Restringir Accesos" />
          </ListItem>
          {/* <ListItem button onClick={() => showPwdResetDialog(selectedUser)}>
            <ListItemIcon>
              <KeyIcon />
            </ListItemIcon>
            <ListItemText primary="Reiniciar Contraseña" />
          </ListItem> */}
          <ListItem button onClick={() => showDeleteUserDialog(selectedUser)}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Eliminar Usuario" />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Button size="medium" onClick={handleCloseMoreOpts} color="inherit" variant="outlined">Cerrar</Button>
      </CardActions>
    </Card>
  );

    const container = window !== undefined ? () => window().document.body : undefined;
    return (
    <Container maxWidth="sm" {...swipeHandlers}>
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box sx={{ ml: 0 }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Tabs value={tabValue } onChange={handleTabChange} aria-label="user tabs" variant="fullWidth" sx={{ mt: 0, mb: 0 }} >
        <Tab label="Activo" value={'activo'}/>
            <Tab label="Pendiente" value={'invitado'}/>
            
        </Tabs>
        <AnimatePresence initial={false} custom={swipeDirection}>
          <motion.div
            key={tabValue}
            custom={swipeDirection}
            variants={pageVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={pageTransition}
          >
            <TextField 
              fullWidth 
              size="medium" 
              id='search' 
              sx={{ textAlign: 'center', mb: 0, mt: 2 }} 
              label="Busqueda" 
              variant="outlined" 
              onChange={handleSearchChange}
              InputProps={{
                  endAdornment: (
                  <InputAdornment position="end">
                      <IconButton>
                      <SearchIcon />
                      </IconButton>
                  </InputAdornment>
                  ),
                  style: { borderRadius: '8px'}
              }}
            />
            {!loading && filteredUsers.length === 0 ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 200px)' }}>
                <Card variant="outlined" sx={{ border: 0, backgroundColor: 'transparent' }} style={{ borderRadius: '10px', textAlign: 'center', width: '100%', paddingTop: '20%', paddingBottom: '40%' }}>
                  <CardContent>
                    {tabValue === 'activo' ? (
                      <>
                        <PersonOffIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} />
                        <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No hay usuarios activos</b></Typography>
                      </>
                    ) : (
                      <>
                        <PersonOffIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} />
                        <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No hay usuarios pendientes de activacion.</b></Typography>
                      </>
                    )}
                    <Divider />
                    <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
                      {tabValue === 'activo' 
                        ? 'Agrega nuevos usuarios usando el botón "Nuevo Usuario".'
                        : 'Luego de agregar usuarios, estos aparecerán en esta sección hasta que complenten su registro.'}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            ) : (
              filteredUsers.map((user,index) => (
                <Box key={user.invcode ? user.invcode : user.username} sx={{my: 0.4}}>
                  <Card variant="outlined" sx={{ border: 0, borderBottom: '1px solid #e0e0e0', borderRadius: '0px'}}>
                    {tabValue === 'invitado' && <a onClick={() => showDeleteUserDialog(user)} style={{ float: 'right', color: 'inherit', textDecoration: 'none', marginTop: "0.8rem", marginRight: "0.8rem" }}>
                      <DeleteIcon color="action"/>
                    </a>} 
                    {tabValue === 'activo' && <a onClick={() => handleMoreOptions(user)} style={{ float: 'right', color: 'inherit', textDecoration: 'none', marginTop: "0.8rem", marginRight: "0.8rem" }}>
                      <MoreVertIcon color="action"/>
                    </a>}
                    <CardContent>
                      <Typography variant="h5" component="div">
                       {user.name} {user.lastname}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Correo: {user.username}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Telefono: {user.phone}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Unidad: {user.unit}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Rol: {user.role}
                      </Typography>
                    </CardContent>
                    <CardActions>
                    {(() => {
                        switch (tabValue) {
                          case 'invitado':
                            return (<>
                              <Button variant="text" color="secondary" onClick={() => resendInvite(user.invcode)}> Reenviar</Button>
                              <Button variant="text" color="secondary" onClick={() => shareInvite(user.invcode)}> Compartir</Button>
                                  </>)
                          case 'activo':
                            return (<>
                              <Button variant="text" color="secondary" onClick={() => handleEditUser(user.username)} startIcon={<EditIcon/>}> 
                                Editar
                              </Button>
                                  </>)
                          default:
                            return <></>
                        }
                      })()}
                      </CardActions>
                    </Card>
                  </Box>
              ))
            )}
          </motion.div>
        </AnimatePresence>
      </Box>
      <Dialog open={deleteUserDialog} onClose={closeDeleteUserDialog}>
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea eliminar al usuario {userToDelete ? userToDelete.name : ''} {userToDelete ? userToDelete.lastname : ''}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteUserDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => deleteUser(userToDelete ? userToDelete.user_id : '')} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={pwdResetDialog} onClose={closePwdResetDialog}>
        <DialogTitle>Confirmar reinicio de contraseña</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea reiniciar la contraseña del usuario {userToReset ? userToReset.name : ''} {userToReset ? userToReset.lastname : ''}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePwdResetDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => resetPwd(userToReset ? userToReset.username : '')} color="secondary">
            Reiniciar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={accessRestrictModal} onClose={closeAccessRestrictModal}>
        <DialogTitle>Restringir Accesos para {selectedUser ? `${selectedUser.name} ${selectedUser.lastname}` : ''}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione los módulos a los que desea restringir el acceso:
          </DialogContentText>
          <FormGroup>
            {Object.entries(modules).map(([key, value]) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={selectedModules[key] || false}
                    onChange={handleModuleChange}
                    name={key}
                  />
                }
                label={value}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAccessRestrictModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={saveAccessRestrictions} color="secondary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
          container={container}
          anchor="bottom"
          variant="temporary"
          open={drawerShow}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
          }}
        >
          {newUserDrawer}
        </Drawer>
      <Drawer
        container={container}
        anchor="bottom"
        variant="temporary"
        open={moreOptsDrawer}
        onClose={handleCloseMoreOpts}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
        sx={{
          display: { xs: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
        }}
      >
        {moreOptsDrawerContent}
      </Drawer>
      <Fab color="secondary" aria-label="add" sx={fabStyle} variant="extended" onClick={handleNewUser}>
        <AddIcon sx={{ mr: 1}}/>
        Nuevo Usuario
      </Fab>
    <BackgroundImage/>
    </Container>
  );
}