import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContactsIcon from '@mui/icons-material/Contacts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FlakyIcon from '@mui/icons-material/Flaky';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import HotelIcon from '@mui/icons-material/Hotel';
import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import AuthService from '../services/auth';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import DeskIcon from '@mui/icons-material/Desk';
import HistoryIcon from '@mui/icons-material/History';
import ChairRoundedIcon from '@mui/icons-material/ChairRounded';
import HomeIcon from '@mui/icons-material/Home';
import ReportIcon from '@mui/icons-material/Report';
import DashboardIcon from '@mui/icons-material/Dashboard';


import axios from 'axios';

import { useNavigate } from 'react-router-dom';

import AppConfig from '../services/app-config';
import InstallPrompt from '../components/InstallPrompt';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const apiUrl = process.env.REACT_APP_MGO_API_URL;
const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

const drawerWidth = 320;
const navItems = [
  { text: 'Inicio', icon: <HomeIcon />, showTo: ["habitante"],link: "residenthub"},
  { text: 'Admin Panel', icon: <DashboardIcon />, showTo: ["admin"],link: "adminhub"},
  { text: 'Recepcion', icon: <DeskIcon />, showTo: ["receptor","admin"] , needsModule: ["visits"]},
  { text: 'Perfil', icon: <AccountCircleIcon />, showTo: ["habitante","receptor","admin"]},
  { text: 'Visitas', icon: <EmojiPeopleIcon />, showTo: ["habitante"] , needsModule: ["visits"]},
  { text: 'Airbnb', icon: <HotelIcon />, showTo: ["habitante"] , needsModule: ["airbnb"], conditionalConfig: {"unit_mode": "airbnb"}, hideForSU: true},
  { text: 'Airbnb', icon: <HotelIcon />, showTo: ["receptor","admin"] , needsModule: ["airbnb"]},
  { text: 'Reportar Avería', icon: <ReportIcon />, showTo: ["habitante","receptor","admin"], link: "reportes", needsModule: ["issues"]},
  { text: 'Amenidades', icon: <ChairRoundedIcon />, showTo: ["habitante","admin"] , needsModule: ["amenities"]},
  { text: 'Residentes', icon: <ContactsIcon />, showTo: ["admin","receptor"], link: "directorio" },
  { text: 'Directorio', icon: <ContactsIcon />, showTo: ["habitante"], conditionalConfig: {"appear_in_directory": true}, hideForSU: true, link: "directorio" },
  { text: 'Admin. de Usuarios', icon: <AdminPanelSettingsIcon />, showTo: ["admin"], link: "useradmin" },
  { text: 'Actividad', icon: <HistoryIcon />, showTo: ["receptor","admin"]},
  { text: 'Parqueo Compartido', icon: <LocalParkingIcon />, showTo: ["habitante"], needsModule: ["parksharing"], link: "parqueos"},
  { text: 'Aprobaciones', icon: <FlakyIcon />, showTo: ["admin"], needsModule: ["amenities"]},
];

const snackBarMessages = {
  "/directorio": { message: "Si no deseas aparecer en el directorio de vecinos, puedes desactivarlo en tu perfil.",
    duration: 15000,
    condition: "directory_fist_time",
    roles: ["habitante"]
  },
  // "/amenidades": { message: "Puedes ver tus reservaciones usando la pestaña superior derecha.",
  //   duration: 8000,  
  //   condition: "amenities_first_time",
  //   roles: ["habitante"]
  // },
  "/nuevoreporte": { message: "Para evitar reportes duplicados, te recomendamos revisar si ya existe un reporte activo antes de enviar uno nuevo.",
    duration: 15000,  
    condition: "new_report_first_time",
    roles: ["habitante"]
  },
  "/visitas": { message: "Si quieres recibir notificaciones sobre tus visitas, reportes o reservas, puedes activar las notificaciones en tu perfil.",
    duration: 10000,
    precondition: { key: "notification_enable", value: false },
    condition: "visits_first_time",
    roles: ["habitante"]
  },
  "/reporte": { message: "Si quieres recibir notificaciones sobre los reportes, puedes activar las notificaciones en tu perfil.",
    duration: 9000,
    precondition: { key: "notification_enable", value: false },
    condition: "issues_first_time",
    roles: ["habitante"]
  },
  "/parqueos": { message: "Si quieres ayudar y compartir tu parqueo registra tu informacion usando el boton de abajo 'Compartir mi Parqueo'.",
    duration: 9000,
    condition: "parking_first_time",
    roles: ["habitante"]
  }
}


const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);


export default function Navbar({getPath,navbarState,setNavbarState,setHistory,history,children}) {
  const navigate = useNavigate();
  const path = getPath();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState(false);
  const [userName, setUserName] = React.useState("Usuario");
  const [userUnit, setUserUnit] = React.useState("Unidad");
  const [userRole, setUserRole] = React.useState("Role");
  const [userOrg, setUserOrg] = React.useState("Org");
  const [snackbar, setSnackbar] = React.useState({open: false, message: "", duration: 3000});
  const theme = useTheme();
  // Change this line to use two breakpoints
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleNavClick = (route) => {
    navigate(`/${route.toLowerCase()}`);
    if (!isDesktop) {
      setMobileOpen(false);
    }
  }

  const handleLogout = () => {
    unregisterNotifications()
  }

  const unregisterNotifications = async () => {
    if (AppConfig.getLocal('notification_token_id')) {
      axios.post(`${apiUrl}/users/notification/unregister`,{token_id: AppConfig.getLocal('notification_token_id')})
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          AuthService.logout()
          navigate('/login')
        }})
        .catch((error) => {
          console.error('Error removing notification token:', error);
          AuthService.logout()
          navigate('/login')
        });
      } else {
      AuthService.logout()
      navigate('/login')
    }
  }

  const isSelected = (menuItem) => {
    if (menuItem.link) {
      return `/${menuItem.link.toLowerCase()}` === path
    }
    return `/${menuItem.text.toLowerCase()}` === path
  }

  const genHeader = () => {
    const pathWithoutParams = path.split('?')[0]; // Remove query parameters for header generation
    switch (pathWithoutParams) {
      case '/':
        return 'Inicio';
      case '/residenthub':
        return 'Inicio';
      case '/adminhub':
        return 'Admin Panel';
      case '/useradmin':
        return 'Admin. de Usuarios';
      case '/pwdreset':
        return 'Restablecer Contraseña';
      case '/login':
        return 'Iniciar Sesion';
      case '/nuevoreporte':
        return 'Nuevo Reporte';
      case '/onboardingparqueo':
        return 'Compartir mi Parqueo';
      case '/qr':
        return 'Escaner QR';
      case '/amenityadmin':
        return 'Admin. de Amenidades';
      case '/solicitudparqueo':
        return 'Solicitud de Parqueo';
      case '/imagenparqueo':
        return 'Detalles del Parqueo';
      default:
        if (pathWithoutParams.startsWith('/airbnb/reservacion')) {
          return 'Reservacion';
        }
        else {
        return pathWithoutParams
          .substring(1)
          .split('/')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
        }
    }
  };

  const handleBackClick = () => {
    if (history.length > 1) {
      const newHistory = history.slice(0, -1);
      const previousPage = newHistory[newHistory.length - 1];
      setHistory(newHistory);
      navigate(previousPage, { replace: true });
    }
  };

  React.useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      handleBackClick();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [history]);

  React.useEffect(() => {
    setUserName(AuthService.getCurrentUser())
    setUserUnit(AuthService.getCurrentUnit())
    setUserRole(AuthService.getCurrentUserRole())
    setUserOrg(AuthService.getCurrentUserOrg())
    setHistory(prevHistory => {
      const filteredHistory = prevHistory.filter(item => item !== '/');
      
      if (filteredHistory.length > 0 && filteredHistory[filteredHistory.length - 1] === path) {
        return filteredHistory;
      }
      return [...filteredHistory.slice(-6), path];
    })

    // Push the current path to the browser history
    window.history.pushState(null, '', path);

    if (path in snackBarMessages) {
      const snackbarMessage = snackBarMessages[path];
      const shouldShowSnackbar = !AppConfig.getLocal(snackbarMessage.condition);
      if (snackBarMessages[path].precondition) {
        const precondition_value = AppConfig.getLocal(snackBarMessages[path].precondition.key);
        if (precondition_value !== snackBarMessages[path].precondition.value) {
          return
        }
      }
      if (shouldShowSnackbar && AuthService.userRoleIncludes(snackbarMessage.roles)) {
        AppConfig.storeLocal(snackbarMessage.condition, true);
        setSnackbar({
          open: true,
          message: snackbarMessage.message,
          duration: snackbarMessage.duration
        });
      }
    }
  }, [userName, userUnit, userRole, userOrg, path])

  

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', pt: '64px' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', pb: 2 }}>
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <img src="/logo.svg" alt="Logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '20%' }} />
          <Typography variant="h6" sx={{ mt: 2, textAlign: 'center' }}>
            {userName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'gray', fontStyle: 'italic', textAlign: 'center' }}>
            {userOrg}: {userUnit}
          </Typography>
          <Divider sx={{ mt: 2 }} />
        </Box>
        <List>
          {navItems.map((item) => {

            if (AuthService.getCurrentUserRole() === "superuser" && item.hideForSU) {
              return null;
            }
            if (item.conditionalConfig) {
              for (const [key, value] of Object.entries(item.conditionalConfig)) {
                if (AppConfig.get(key) !== value) {
                  return null;
                }
              }

            }
            if (item.needsModule) {
              let meetsModules = true
              for (const module of item.needsModule) {
                if (!AppConfig.get("modules").includes(module)) {
                  meetsModules = false;
                  break;
                }
              }
              if (!meetsModules) {
                return null;
              }
            }
            return AuthService.userRoleIncludes(item.showTo) &&
              <ListItem key={item.text} disablePadding>
                <ListItemButton selected={isSelected(item)} onClick={() => handleNavClick(item.link ?? item.text)} sx={{ textAlign: 'center' }}>
                  <ListItemText primary={item.text} />
                  {item.icon}
                </ListItemButton>
              </ListItem>;
          })}     
        </List>
      </Box>
      
      <Box sx={{ borderTop: 1, borderColor: 'divider', pt: 0, pb: 0 }}>
        <List sx={{ p: 0 }}>          
          {AuthService.isLogged() ? (
            <>
            
              {!AppConfig.isInstalled() && AppConfig.getDevicePlatform() !== "chrome"  && (
                <ListItem disablePadding>
                  <InstallPrompt />
                </ListItem>
              )}
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }} onClick={handleLogout}>
                  <ListItemText primary='Cerrar Sesion' sx={{ textAlign: 'center' }} />
                  <LogoutIcon />
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} onClick={() => handleNavClick('login')}>
                <ListItemText primary='Iniciar Sesion' sx={{ textAlign: 'center' }} />
                <LoginIcon />
              </ListItemButton>
            </ListItem>
          )}
        </List>
        <Typography variant="body2" sx={{ color: 'gray', fontStyle: 'italic', textAlign: 'center', fontSize: "40%", mt: -1 }}>
          v{process.env.REACT_APP_APP_VERSION}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Permanent Drawer for Desktop */}
      {isDesktop && (
        <SwipeableDrawer
          variant="permanent"
          open
          onOpen={() => {}}
          onClose={() => {}}
          sx={{
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth,
              borderRight: '1px solid rgba(0, 0, 0, 0.12)',
              boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
            },
          }}
        >
          {drawer}
        </SwipeableDrawer>
      )}

      {/* Temporary Drawer for Mobile and Tablet */}
      {!isDesktop && (
        <SwipeableDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
          SwipeAreaProps={{
            swipeAreaWidth: isMobile ? 100 : 0
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </SwipeableDrawer>
      )}

      <Box component="main" sx={{ 
        flexGrow: 1,
        ml: isDesktop ? `${drawerWidth}px` : 0,
        width: isDesktop ? `calc(100% - ${drawerWidth}px)` : '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <AppBar position="fixed" sx={{
          width: isDesktop ? `calc(100% - ${drawerWidth}px)` : '100%',
          ml: isDesktop ? `${drawerWidth}px` : 0,
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{ mr: 0.5, display: isDesktop ? 'none' : 'block' }}
            >
              <MenuIcon />
            </IconButton>
            {history.length > 1 && navbarState.showBack && (
              <Button
                color="inherit"
                startIcon={<ChevronLeftIcon />}
                onClick={handleBackClick}
                sx={{
                  mr: 1,
                  textTransform: 'none',
                  borderRadius: '20px',
                  padding: '4px 12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                }}
              >
                Atrás
              </Button>
            )}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: { sm: 'block' },
                fontFamily: 'Lexend, sans-serif',
                fontWeight: 'bold',
                textAlign: { xs: 'left', md: 'right' }, // Left-aligned on mobile, right-aligned on desktop
                pr: { md: 2 }, // Add some padding on the right for desktop
              }}
            >
              {genHeader()}
            </Typography>

            {navbarState.showOptions && (
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="open options"
                onClick={handleDrawerToggle}
                sx={{ ml: 2 }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <Toolbar /> {/* This creates space below the AppBar */}
        <Box component="div" sx={{ 
          flexGrow: 1,
          p: 0, 
          width: '100%',
          maxWidth: { sm: '100%', md: '1200px' }, 
          mx: 'auto',
          position: 'relative',
          zIndex: 1
        }}>
          {children}
        </Box>
        <Snackbar
            open={snackbar.open}
            autoHideDuration={snackbar.duration}
            onClose={() => setSnackbar({...snackbar, open: false})}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{
              mt: "3.5rem",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              borderRadius: "1rem",
              border: "0.6px solid #4871e6",
              backgroundColor: "#b7cbf9",
            }}
        >
          <Box sx={{ p: 3, color: "#021545", backgroundColor: "#4871e6", borderRadius: "1rem" }}>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setSnackbar({...snackbar, open: false})}
            sx={{
              position: 'absolute',
              top: 0,
              right: 6,
              color: 'white' // Add this line to make the IconButton white
            }}
          >
            <CloseIcon />
          </IconButton>
            <Typography variant='body2' sx={{ mt: 1, color: "white", fontSize: "95%" }}>
              {snackbar.message}
            </Typography>
          </Box>
        </Snackbar>
      </Box>
    </Box>
  );
}