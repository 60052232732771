import React from 'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Checkbox from '@mui/material/Checkbox';

import Button from '@mui/material/Button';

import AppConfig from '../services/app-config';


import Resizer from 'react-image-file-resizer';



const FormInput = ({formConfig,setFormConfig,localeStrings,axios}) => {
    const [image, setImage] = React.useState(null);
    const current_step = formConfig.steps[formConfig.current_step];

    const handleImageUpload = (e, current_step_name) => {
      const file = e.target.files[0];
      if (file) {
        const maxSizeKB = 150; // Maximum allowed size in KB
        const maxSizeBytes = maxSizeKB * 1024;
    
        const reader = new FileReader();
    
        reader.onloadend = () => {
          // This is where you get the base64 encoded string
          const base64String = reader.result;
    
          if (file.size > maxSizeBytes) {
            // Image is too large, resize it
            Resizer.imageFileResizer(
              file,
              800, // Max width
              800, // Max height
              'JPEG', // Output format
              80, // Quality
              0, // Rotation
              (blob) => {
                // Read the resized blob as a data URL (base64)
                const resizedReader = new FileReader();
                resizedReader.onloadend = () => {
                  // Update formConfig with the resized base64 string
                  setFormConfig({
                    ...formConfig,
                    steps: {
                      ...formConfig.steps,
                      [formConfig.current_step]: {
                        ...formConfig.steps[formConfig.current_step],
                        data: {
                          ...formConfig.steps[formConfig.current_step].data,
                          [current_step_name]: {
                            ...formConfig.steps[formConfig.current_step].data[
                              current_step_name
                            ],
                            value: resizedReader.result, // Store the resized base64 string
                          },
                        },
                      },
                    },
                  });
                };
                resizedReader.readAsDataURL(blob);
              },
              'blob', // Output type
              maxSizeKB / 1024 // Max size in MB
            );
          } else {
            // Image is within size limit, use the base64 string directly
            setFormConfig({
              ...formConfig,
              steps: {
                ...formConfig.steps,
                [formConfig.current_step]: {
                  ...formConfig.steps[formConfig.current_step],
                  data: {
                    ...formConfig.steps[formConfig.current_step].data,
                    [current_step_name]: {
                      ...formConfig.steps[formConfig.current_step].data[
                        current_step_name
                      ],
                      value: base64String, // Store the base64 string
                    },
                  },
                },
              },
            });
          }
        };
    
        // Read the file as a data URL (base64)
        reader.readAsDataURL(file);
      }
    };
    


const createTextField = (current_step_name) => {
    const current_step_obj = current_step.data[current_step_name];
    let mandatory = ""
    if (current_step_obj.required) {
        mandatory = " *"
    }
    return <TextField key={current_step_name} {...current_step_obj.props_dict}
            sx={{ my: 2 }} 
            label={`${current_step_obj.label[formConfig.language]}${mandatory}`} 
            value={formConfig.steps[formConfig["current_step"]].data[current_step_name].value}
            onChange={(e) => {
                let input = e.target.value
                if (current_step_obj.capitalize) {
                    input = AppConfig.titleCase(e.target.value)
                } 
              
                setFormConfig({...formConfig, steps: {...formConfig.steps, [formConfig.current_step]: {...formConfig.steps[formConfig.current_step], data: {...formConfig.steps[formConfig.current_step].data, [current_step_name]: {...formConfig.steps[formConfig.current_step].data[current_step_name], value: input}}}}})
            }}
            helperText={current_step_obj.helper ? current_step_obj.helper[formConfig.language] : null}
            />;
};

const createImgInputField = (current_step_name) => {
  const current_step_obj = current_step.data[current_step_name];
  return (
    <Box key={current_step_name} sx={{ my: 2 }}>
      {!current_step_obj.submitted && <Typography variant="caption" color="textSecondary">
        {current_step_obj.label[formConfig.language]}
      </Typography>}
      {current_step_obj.submitted ? 
      (
        <>
        <Typography variant="h1" color="textSecondary">
          <DoneAllIcon/>
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through' }}>
          {current_step_obj.label[formConfig.language]}
        </Typography>
        <Typography variant="body1">
          {localeStrings[formConfig.language].field_completed}
        </Typography>
        </>
        
      ):(
      <>
      {current_step_obj.value ? (
        <Box style={{ position: 'relative' }}>
          <img
            src={formConfig.steps[formConfig.current_step].data[current_step_name].value}
            alt="Uploaded"
            style={{
              maxWidth: '300px',
              height: 'auto',
              objectFit: 'cover',
              borderRadius: '10px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              marginTop: '8px',
            }}
          />
        </Box>
      ):(
        <Box style={{ position: 'relative' }}>
          <img
            src="amenityplaceholder.png"
            alt="No Image"
            style={{
              maxWidth: '300px',
              height: 'auto',
              objectFit: 'cover',
              borderRadius: '10px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              marginTop: '8px',
            }}
          />
        </Box>
      )}
      </>
      )}
      {!current_step_obj.submitted && 
      <>
      <input
        accept="image/*"
        id={current_step_name}
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => handleImageUpload(e, current_step_name)}
      />
      <label htmlFor={current_step_name}>
        <Button variant="outlined" component="span" sx={{ mt: 1 }} fullWidth>
          {current_step_obj.cta[formConfig.language]}
        </Button>
      </label>
      </>
      }
      {current_step_obj.helper && (
        <Typography variant="caption" color="textSecondary">
          {current_step_obj.helper[formConfig.language]}
        </Typography>
      )}
    </Box>
  );
};

        
    return (
        <Box sx={{ textAlign: 'center', alignItems: 'center', mb: 2 }}>
            <Typography variant="h5" color="textSecondary" sx={{ mb: 1 }}>
                <b>{current_step.title[formConfig.language]}</b>
            </Typography>
        <Box
              component="img"
              src={`/orgs/${formConfig.org.toLowerCase()}.png`}
              alt={`${formConfig.org}-logo`}
              sx={{
                width: '60px',
                height: '60px',
                objectFit: 'contain',
              }}
            />
        <Card sx={{ mt: 4, py: 2}} variant="outlined">
            <CardContent>
                    
                {Object.entries(current_step.data).map(([stepName, stepData]) => {
                if (stepData.type === "TextField") {
                    return createTextField(stepName);
                } else if (stepData.type === "Image") {
                    return createImgInputField(stepName);
                }
                // Handle other types as needed, or simply return null
                return null; 
                })}

            </CardContent>
        </Card>
    </Box>
                    )

}

export default FormInput;