import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import BackgroundImage from '../components/Cityline';
import AuthService from '../services/auth';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ChairRoundedIcon from '@mui/icons-material/ChairRounded';
import ReportIcon from '@mui/icons-material/Report';
import HomeIcon from '@mui/icons-material/Home';
import ContactsIcon from '@mui/icons-material/Contacts';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Navigate } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
import eventReportService from '../services/event-report';
import AppConfig from '../services/app-config';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';
import ReactAnimatedWeather from 'react-animated-weather';
import HotelIcon from '@mui/icons-material/Hotel';

import axios from 'axios';

const ResidentHub = () => {
    const [org, setOrg] = React.useState("");
    const [weather, setWeather] = React.useState({ temp: "", icon: 'NONE',fetched: false });
    const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
    const [status, setStatus] = React.useState({issues: null, amenities: null, parking: null});

    let event = new URLSearchParams(location.search).get('event');

    const userCors = process.env.REACT_APP_USE_CORS;
    if ( userCors === "true") {
        axios.defaults.withCredentials = true
    }
    const apiUrl = process.env.REACT_APP_MGO_API_URL

    const getWeather = async () => {
        try {
            const response = await axios.get(`${apiUrl}/weather/current`);
            setWeather({ temp: response.data.data.temp, icon: response.data.data.icon, fetched: true });
        } catch (error) {
            console.error(error);
        }
    }

    const fetchStatus = async () => {
        try {
            const response = await axios.get(`${apiUrl}/habitante/status`);
            AppConfig.parseResponse(response.data);
            setStatus(response.data.data);
            console.log(response.data.data);

        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.code === 401) {
                setRedirect({ trigger: true, route: '/login' })
              }
        }
    }

    React.useEffect(() => {
        setOrg(AuthService.getCurrentUserOrg());
        fetchStatus();
        getWeather();
        if (event !== null) {
            console.log(event)
            eventReportService(event, { device: AppConfig.getDevicePlatform(), installed: AppConfig.isInstalled() });
            setRedirect({ trigger: true, route: '/residenthub' });
            event = null;
        }
    }, []);

    const handleClick = (route) => {
        setRedirect({ trigger: true, route: `/${route.toLowerCase()}` })
    }

    const badgeStyle = {
        '& .MuiBadge-badge': {
          right: 15,
          top: 15,
          padding: '0 4px',
          height: '22px',
          minWidth: '22px',
        },
        width: '100%', // Ensure the Badge takes full width
    };

    const cardStyle = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        },
    };

    const remCardStyle = {
        height: '100%',
        display: 'flex',
        border: 0,
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#4871e6./'
    };


    const iconStyle = {
        position: 'absolute',
        right: -20,
        bottom: -20,
        fontSize: 105,
        color: 'rgba(0, 0, 0, 0.10)',
    };

    const homeIconStyle = {
        position: 'absolute',
        left: 25,
        bottom: -80,
        fontSize: 240,
        color: 'rgba(0, 0, 0, 0.04)',
    };

    const weatherIconStyle = {
        position: 'absolute',
        ml: 2
    }

    const SuperC = styled('sup')({
        fontSize: '0.6em',
        verticalAlign: 'super',
      });

    const widgetSizes = {
        visitas: AppConfig.haveModule('amenities') ? 6 : 12,
        amenidades: AppConfig.haveModule('visits') ? 6 : 12,
        airbnb: 12
    }

    return (
        <Container maxWidth="sm">
            {redirect.trigger && (
                <Navigate to={redirect.route} replace={true} />
            )}
            <Box sx={{ mt: 0 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sx={{mb: -3}}>
                        <Card sx={{ ...cardStyle, bgcolor: 'white', position: 'relative', boxShadow: 0 , pb: 3}}>
                            
                            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>
                                {org}
                                </Typography>
                                <Box sx={{ display: !weather.fetched ? "flex" : "none", ml: 2, mt: 1 }}>
                                <CircularProgress 
                                        size={26}
                                    />
                                </Box>
                                <Box sx={{ display: weather.fetched ? "flex" : "none", alignItems: 'center', ml: 2, mt: 1 }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', mr: 1 }}>
                                    {weather.temp}<SuperC>o</SuperC>C
                                </Typography>
                                <ReactAnimatedWeather
                                    icon={weather.icon}
                                    color='black'
                                    size={34}
                                    animate={true}
                                />
                                </Box>
                            </Box>
                                <Box
                                    component="img"
                                    src={`/orgs/${org.toLowerCase()}.png`}
                                    alt={`${org}-logo`}
                                    sx={{
                                        width: '60px',
                                        height: '60px',
                                        objectFit: 'contain',
                                    }}
                                />
                            </CardContent>
                            <HomeIcon sx={homeIconStyle} />
                            
                        </Card>
                    </Grid>
                    <Grid item xs={widgetSizes.airbnb}  sx={{ display: AppConfig.haveModule('airbnb') && AppConfig.get('unit_mode') === "airbnb" ? "block" : "none" }}>
                        <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }} onClick={event => handleClick('airbnb')}>
                            <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Airbnb
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Gestiona las reservaciones de tu Airbnb
                                </Typography>
                            </CardContent>
                            <HotelIcon sx={iconStyle} />
                        </Card>
                    </Grid>
                    <Grid item xs={widgetSizes.visitas}  sx={{ display: AppConfig.haveModule('visits') ? "block" : "none" }}>
                        <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }} onClick={event => handleClick('visitas')}>
                            <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Visitas
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Gestiona tus visitas
                                </Typography>
                            </CardContent>
                            <EmojiPeopleIcon sx={iconStyle} />
                        </Card>
                    </Grid>
                    <Grid item xs={widgetSizes.amenidades} sx={{ display: AppConfig.haveModule('amenities') ? "block" : "none" }}>
                        <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative'}}  onClick={event => handleClick('amenidades')}>
                            <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Amenidades
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Reserva y explora las amenidades
                                </Typography>
                            </CardContent>
                            <ChairRoundedIcon sx={iconStyle} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sx={{ display: status.amenities > 0 ? "block" : "none" }}>
                        <Badge badgeContent={status.amenities} color="primary" max={99} sx={{ ...badgeStyle, width: '100%' }}>
                            <Card sx={{ ...remCardStyle, overflow: 'hidden', position: 'relative', width: '100%', p: 0, bgcolor: '#4871e6' }}  onClick={event => handleClick('amenidades?tab=reservations')}>
                                <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 0.5, color: 'white' }}>
                                    {status.amenities === 1 ? 'Reservacion Vigente' : 'Reservaciones Vigentes'}
                                    </Typography>
                                    {status.amenities > 0 && (
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem', color: 'white' }}>
                                            Tienes {status.amenities} {status.amenities === 1 ? 'reservacion vigente para hoy' : 'reservaciones vigentes para hoy'}
                                        </Typography>
                                    )}
                                </CardContent>
                                <EventAvailableIcon sx={iconStyle} />
                            </Card>
                        </Badge>
                    </Grid>
                    <Grid item xs={12} sx={{ display: AppConfig.haveModule('issues') ? "block" : "none" }}>
                        <Badge badgeContent={status.issues} color="primary" max={99} sx={{ ...badgeStyle, width: '100%' }}>
                            <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative', width: '100%' }}  onClick={event => handleClick('reportes')}>
                                <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        Reportar Avería
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Reporta o informate de problemas en {org}
                                    </Typography>
                                    {status.issues > 0 && (
                                        <Typography variant="body2" color="text.primary" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                            {status.issues} {status.issues === 1 ? 'reporte abierto' : 'reportes abiertos'}
                                        </Typography>
                                    )}
                                </CardContent>
                                <ReportIcon sx={iconStyle} />
                            </Card>
                        </Badge>
                    </Grid>
                    <Grid item xs={12} sx={{ display:  AppConfig.haveModule('parksharing') ? "block" : "none" }}>
                        <Badge badgeContent={status.pending_requests} color="primary" max={99} sx={badgeStyle}>
                            <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative', width: '100%' }} onClick={event => handleClick('parqueos')}>
                                <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        Parqueos Compartidos
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Solicita o comparte parqueos con tus vecinos
                                    </Typography>
                                    {status.pending_requests > 0 && (
                                        <Typography variant="body2" color="text.primary" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                            {status.pending_requests} {status.pending_requests === 1 ? 'solicitud pendiente' : 'solicitudes pendientes'}
                                        </Typography>
                                    )}
                                </CardContent>
                                <LocalParkingIcon sx={iconStyle} />
                            </Card>
                        </Badge>
                    </Grid>
                    <Grid item xs={12} sx={{ display: AppConfig.get('appear_in_directory') ? "block" : "none" }}>
                        <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }}  onClick={event => handleClick('directorio')}>
                            <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Directorio
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Busca a tus vecinos en {org}
                                </Typography>
                            </CardContent>
                            <ContactsIcon sx={iconStyle} />
                        </Card>
                    </Grid>
                    
                </Grid>
            </Box>
            <BackgroundImage />
        </Container>
    );
};

export default ResidentHub;