import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Navbar from './components/Navbar';
import InAppNotification from './components/InAppNots';
import Login from './pages/Login';
import Visits from './pages/Visits';
import ShareCode from './pages/ShareCode';
import Home from './pages/Home';
import Camera from './pages/Camera';
import Reception from './pages/Reception';
import Profile from './pages/Profile';
import Register from './pages/Register';
import Directory from './pages/Directory';
import UserAdmin from './pages/UserAdmin';
import Activity from './pages/Activity';
import ReportError from './pages/ReportError';
import PasswordReset from './pages/PasswordReset';
import Amenities from './pages/Amenities';
import Amenity from './pages/Amenity';
import Reservation from './pages/Reservation';
import Approvals from './pages/Approvals';
import IssueReport from './pages/IssueReport';
import Issue from './pages/Issue';
import NewReport from './pages/NewReport';
import QRCode from './pages/QRCode';
import AllReservations from './pages/AllReservations';
import ImgUpload from './pages/ImgUploadSU';
import AmenityAdmin from './pages/AmenityAdmin';
import ResidentHub from './pages/ResidentHub';
import AdminHub from './pages/AdminHub';
import ParkingOnboarding from './pages/ParkingOnboarding';
import ParkingShare from './pages/ParkingShare';
import ParkRequest from './pages/ParkRequest';
import ShareParksharingImage from './pages/ShareParksharingImage';
import AirbnbForm from './pages/AirbnbForm';
import Airbnb from './pages/Airbnb';
import AirbnbReservation from './pages/AirbnbReservation';
import { initializeApp } from "firebase/app";

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyANkG9-gqHdhK5byRpIxJUKPWMHcHCBsZ4",
    authDomain: "migaritaonline.firebaseapp.com",
    projectId: "migaritaonline",
    storageBucket: "migaritaonline.appspot.com",
    messagingSenderId: "279037079450",
    appId: "1:279037079450:web:671127ed54558d9b3f1423",
    measurementId: "G-9S0D4WWFQJ"
  };
  const app = initializeApp(firebaseConfig);

  const routesWithoutNavbar = ['/login', '/registro',"/guestform"]; 

  var last_path = null;

  const location = useLocation();
  const navigate = useNavigate(); // Now inside BrowserRouter

  const getPath = () => {
    return location.pathname + location.search;
  }

  const navbarOptions = {
    showSeach: false,
    showOptions: false,
    showBack: false,
    pageName: "",
    options: []
  }

  const [navbarState, setNavbarState] = React.useState(navbarOptions);
  const [history, setHistory] = React.useState([]);

  function RouteChangeHandler({ setNavbarState }) {
    if (last_path === location.pathname) {
      return null;
    }
    if (last_path !== null) {
      console.log("Route changed to:", location.pathname);
      setNavbarState(navbarOptions);
      console.log(history);
    }
    last_path = location.pathname;
    return null; // This component doesn't render anything
  }

  useEffect(() => {
    const handleSwipe = (event) => {
      if (event.direction === 'right') {
        if (!hasTabs || isLeftMostTab) {
          // Show sidebar
          setSidebarVisible(true);
        }
      }
    };

    window.addEventListener('swipe', handleSwipe);
    return () => window.removeEventListener('swipe', handleSwipe);
  });

  return (
    <>
      <RouteChangeHandler setNavbarState={setNavbarState} />
      {!routesWithoutNavbar.includes(location.pathname) ? (
        <Navbar
          getPath={getPath}
          navbarState={navbarState}
          setNavbarState={setNavbarState}
          history={history}
          setHistory={setHistory}
        >
          <InAppNotification />
          <Routes>
            <Route index element={<Home />} />
            <Route path="/compartir" element={<ShareCode setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/visitas" element={<Visits setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/qr" element={<QRCode setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/recepcion" element={<Reception setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/perfil" element={<Profile setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/directorio" element={<Directory setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/useradmin" element={<UserAdmin setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/nuevoreporte" element={<NewReport setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/actividad" element={<Activity setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/pwdreset" element={<PasswordReset setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/camara" element={<Camera setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/amenidades" element={<Amenities setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/amenidad" element={<Amenity setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/reservacion" element={<Reservation setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/reservaciones" element={<AllReservations setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/aprobaciones" element={<Approvals setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/reportes" element={<IssueReport setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/reporte" element={<Issue setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/residenthub" element={<ResidentHub setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/imgupload" element={<ImgUpload setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/amenityadmin" element={<AmenityAdmin setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/adminhub" element={<AdminHub setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/onboardingparqueo" element={<ParkingOnboarding setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/parqueos" element={<ParkingShare setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/solicitudparqueo" element={<ParkRequest setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/imagenparqueo" element={<ShareParksharingImage setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/airbnb" element={<Airbnb setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/airbnb/reservacion/:reservationId" element={<AirbnbReservation setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Navbar>
      ) : (
        <>
          <InAppNotification />
          <Routes>
            <Route path="/login" element={<Login setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/registro" element={<Register setNavbarState={setNavbarState} navbarState={navbarState} />} />
            <Route path="/guestform" element={<AirbnbForm setNavbarState={setNavbarState} navbarState={navbarState} />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;