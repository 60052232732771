import React from'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const AcceptRules = ({formConfig,localeStrings,axios}) => {
    const [rules, setRules] = React.useState({});
    const [loaded,setLoaded] = React.useState(false);
    const [ruleCount,setRuleCount] = React.useState(0);


    const current_step = formConfig.steps[formConfig.current_step];
    const apiUrl = process.env.REACT_APP_MGO_API_URL;

    const fetchRules = () => {
        axios.get(`${apiUrl}/form/rules/${current_step.accept}`)
        .then((response) => {
            let response_data = response.data.data
            setRuleCount(response_data.es.rules.length);
            setRules(response_data);
            setLoaded(true);
        })
    }

    React.useEffect(() => {
        fetchRules();
    }, [])

    return (
        <Box sx={{ textAlign: 'center', alignItems: 'center', mb: 4 }}>
            {loaded && <Typography variant="h5" color="textSecondary" sx={{ mb: 1 }}>
                <b>{rules[formConfig.language].title}</b>
            </Typography>}
            <Box
              component="img"
              src={`/orgs/${formConfig.org.toLowerCase()}.png`}
              alt={`${formConfig.org}-logo`}
              sx={{
                width: '60px',
                height: '60px',
                objectFit: 'contain',
              }}
            />
            
            <Card sx={{ mt: 4, px: 4 }} variant="outlined">
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <ChecklistIcon sx={{ fontSize: 60, mb: 0 }} color="secondary" />
                        <Typography variant="h6" color="textSecondary" sx={{ mt: 5, display: 'flex', alignItems: 'flex-start' }}>
                            {loaded ? 
                            rules[formConfig.language].rules[current_step.rule_index]
                            :
                            <CircularProgress />}
                        </Typography>
                        {current_step.accepted[current_step.rule_index] && <Chip 
                          icon={<CheckCircleIcon style={{ color: 'green' }} />}
                          label={localeStrings[formConfig.language].already_accepted} 
                          color="default" 
                          sx={{ mt: 2 }} 
                        />}

                    </Box>
                </CardContent>
            </Card>
            {loaded && 
              <Stepper activeStep={current_step.rule_index} alternativeLabel sx={{ mt: 4 }}>
                {[...Array(ruleCount)].map((_, index) => (
                  <Step key={index}>
                    <StepLabel></StepLabel>
                  </Step>
                ))}
              </Stepper>
              
            }
            
        </Box>
                    )

}

export default AcceptRules;
