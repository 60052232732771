import * as React from 'react';
import Container from '@mui/material/Container';
import { Navigate, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import axios from 'axios';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import "../App.css"
import BackgroundImage from '../components/Cityline';
import ReportIcon from '@mui/icons-material/Report';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';

import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Slide from '@mui/material/Slide';

import Input from '@mui/material/Input';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Resizer from 'react-image-file-resizer';
import ClearIcon from '@mui/icons-material/Clear';

import AppConfig from '../services/app-config';
import VerificationInput from "react-verification-input";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { useRef } from 'react';
import Fab from '@mui/material/Fab';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PeopleIcon from '@mui/icons-material/People';
import HotelIcon from '@mui/icons-material/Hotel';
import ContactsIcon from '@mui/icons-material/Contacts';
import ReactAnimatedWeather from 'react-animated-weather';
import AuthService from '../services/auth';
import styled from '@mui/material/styles/styled';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Join({setNavbarState, navbarState}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' })
  const [loadingPreview, setLoadingPreview] = React.useState(true)
  const [loadinQuery, setLoadingQuery] = React.useState(true)
  const [visitas, setVisitas] = React.useState('')
  const [code, setCode] = React.useState('')
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
  const [successDialog, setSuccessDialog] = React.useState(false)
  const [plates, setPlates] = React.useState("")
  const [dialogSearchIsOpen, setDialogSearchIsOpen] = React.useState(false)
  const [isInvalid, setIsInvalid] = React.useState(false)
  const [dialogData, setDialogData] = React.useState(null)
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [image, setImage] = React.useState(null);
  const [data, setData] = React.useState([]); // New state variable for received data
  const [search, setSearch] = React.useState(''); // New state variable for search
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const navigate = useNavigate();
  let redirectCurious = false;
  const [org, setOrg] = React.useState("");
  const [weather, setWeather] = React.useState({ temp: "", icon: 'NONE', fetched: false });

  var scannedCode = new URLSearchParams(location.search).get('codigo');
  var checkinCoordination = {code_submitted: false, image_submitted: false}
  if (scannedCode !== null) {
    scannedCode = scannedCode.toUpperCase();
    redirectCurious = true;
  }
  const qrState = new URLSearchParams(location.search).get('qr');

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const handleCodeInput = (e) => {
    const strcode = e.toUpperCase()
    setCode(strcode)
    if (strcode.length === 6) {
      handleVerificarPress(strcode)
    }
  }

  function titleCase(str) {
    return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  const handleIdImageChange = (e) => {
    const file = e.target.files[0];
      if (file.size > 1024 * 1024) {
          Resizer.imageFileResizer(
              file,
              800, // Max width
              800, // Max height
              'JPEG', // Output format
              80, // Quality
              0, // Rotation
              (compressedFile) => {
                  setImage(compressedFile);
              },
              'blob' // Output type
          );
      } else {
          setImage(file);
      }
  }

  const handleVehiclePlateChange = (event) => {
    setPlates(event.target.value.toUpperCase());
  };
  
  const handleVerificarPress = (autoinput_code="null") => {
    let data;
    if (typeof autoinput_code === 'string' || autoinput_code instanceof String) {
      data = { "code": autoinput_code }
      setCode(autoinput_code)
    } else  {
      data = { "code": code }
    }
    if (data.code.length !== 6) {
      setSnackbar({ open: true, message: 'El codigo debe tener 6 digitos.', severity: 'warning' })
      return
    }
    setLoadingQuery(true)
    setDialogIsOpen(true)
    setIsInvalid(false)
    axios.post(`${apiUrl}/visit/query`, data)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setDialogData(response.data.data);
          console.log(response.data.data)
          if (response.data.data.type && response.data.data.type === "airbnb") {
            navigate(`/airbnb/reservacion/${response.data.data.airbnb_reservation}`) 
          }
          if (response.data.data.vehicle_plate) {
            setPlates(response.data.data.vehicle_plate)
          }
          setDialogIsOpen(true)
          setLoadingQuery(false)
        } 
        else if (response.status === 401 && redirectCurious) {
          window.location.href = 'https://migarita.online'
        } 
        else {
          console.error("Error verifying code: ", response.status);
          setDialogIsOpen(false)
          setLoadingQuery(false)
        } 
      })
      .catch(error => {
        setIsInvalid(true)
        setLoadingQuery(false)
      })
  }

  const handleSearchPress = () => {
    setLoadingQuery(true)
    axios.get(`${apiUrl}/visits/all`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setData(response.data.data);
          setVisitas(response.data.data.active.length)
          setDialogSearchIsOpen(true)
          setLoadingQuery(false)
          
        } else {
          console.error("Error verifying code: ", response.status);
          setDialogIsOpen(false)
          setLoadingQuery(false)
        }
      })
      .catch(error => {
        setIsInvalid(true)
        setLoadingQuery(false)
      })
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  }
  React.useEffect(() => {
    setNavbarState({ showSearch: false, showOptions: false, showBack: true});
    if (dialogSearchIsOpen) {
      setLoadingQuery(true)
      axios.get(`${apiUrl}/visits/all`)
        .then(response => {
          AppConfig.parseResponse(response);
          if (response.status === 200) {
            setData(response.data.data)
            setLoadingQuery(false)
          } else {
            console.error("Error querying data: ", response.status);
            setLoadingQuery(false)
          }
        })
        .catch(error => {
          console.error("Error querying data: ", error);
          setLoadingQuery(false)
        })
    }
  }, [dialogSearchIsOpen]);

  const renderData = (state) => {
    // First filter the data
    const filteredData = data[state].filter(item => 
      item.code.toLowerCase().includes(search.toLowerCase()) || 
      item.visit_name.toLowerCase().includes(search.toLowerCase()) || 
      item.unit.toLowerCase().includes(search.toLowerCase())
    );
    // Render the filtered data
    return filteredData.map(item => (
      <Grid item xs={12} sm={6} md={4} key={item.code}>
        <Card style={{ borderRadius: '10px' }}>
          <CardContent>
            <Typography variant="h5">{item.code}</Typography>
            <Box display="flex" alignItems="center">
              {item.type && item.type === 'airbnb' && <img src="/airbnb-logo.png" alt="Airbnb Logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />}
              <Typography variant="h6">{item.visit_name}</Typography>
            </Box>
            <Typography color="text.secondary">{AppConfig.get('unit_type')} {item.unit}</Typography>
            <Typography color="text.secondary">Creado {AppConfig.formatFriendlyDate(item.ts)}</Typography>
            <Typography color="text.secondary">{item.ts_used ? `Usado ${AppConfig.formatFriendlyDate(item.ts_used)}`: ''}</Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => handleVerificarPress(item.code)} size="small" color="primary">
                Abrir
            </Button>
          </CardActions>
        </Card>
      </Grid>
    ));
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const closeDialog = () => {
    setDialogIsOpen(false);
    setDialogData(null);
    setImage(null);
  };

  const generateEpochTimestamp = () => {
    return Math.floor(Date.now() / 1000);
  };

  const closeSearchDialog = () => {
    setDialogSearchIsOpen(false);
  };

  const closeSuccessDialog = () => {
    setSuccessDialog(false);
  };

  const widgetSizes = {
    directorio: AppConfig.haveModule('issues') ? 6 : 12,
    airbnb: 12,
  }

  const handleClick = (route) => {
    setRedirect({ trigger: true, route: `/${route.toLowerCase()}` })
  }


  const syncRequests = () => {
    if (!AppConfig.getLocal("require_id_image")) {
      checkinCoordination.image_submitted = true
    }
    if (checkinCoordination.code_submitted && checkinCoordination.image_submitted) {
      setDialogIsOpen(false);
      setDialogSearchIsOpen(false);
      closeDialog();
      fetchVisitsData();
      setLoadingQuery(false);
      setSuccessDialog(true)
      setCode('')
      setTimeout(function() {
        closeSuccessDialog();
      }, 1800);
    }  
  }

  const uploadId = (image_id) => {
    const formData = new FormData();
    formData.append('image_id', image_id);
    if (image) {
        formData.append('image', image);
    }
    axios.post(`${apiUrl}/visit/id/upload`, formData)
        .then((response) => {
            AppConfig.parseResponse(response);
            if (response.status === 200) {
                checkinCoordination.image_submitted = true
                syncRequests()
              }
        })
        .catch((error) => {
            if(error.response && error.response.data && error.response.data.code === 422) {
            setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
            }
            else  {
            console.error('Error commenting:', error);
            setSnackbar({ open: true, message: "Hubo un error al intentar subir la imagen de la identificacion", severity: 'error' })};
        });
  };

  const expireCode = () => {
    var image_id = false;
    checkinCoordination = {code_submitted: false, image_submitted: false}
    if (AppConfig.getLocal("require_id_image") && !image) {
      setSnackbar({ open: true, message: 'Es necesario tomar una foto de la identificacion. (DPI o Licencia)', severity: 'warning' })
      return
    }
    
    if (AppConfig.getLocal("require_id_image")) {
      image_id = `${code}-${generateEpochTimestamp()}`;
      uploadId(image_id);
    }

    setLoadingQuery(true)
    axios.post(`${apiUrl}/visit/checkin`, {"access_code": code, "plates": plates, "image_id": image_id})
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          checkinCoordination.code_submitted = true
          syncRequests()
        } else {
          console.error("Error verifying code: ", response.status);
          setDialogIsOpen(false)
          setLoadingQuery(false)
        }
      })
      .catch(error => {
        if (error.response.data.code === 423) {
          setDialogIsOpen(false)
          setDialogSearchIsOpen(false)
          setSnackbar({ open: true, message: 'El codigo ya ha sido utlizado, no es permitido re-ingresar con este mismo codigo.', severity: 'warning' })
        }
        setIsInvalid(false)
        setLoadingQuery(false)
        setCode('')
      })
  };

  const fileInputRef = useRef(null);

  const handleUploadImage = () => {
    fileInputRef.current.click();
  };


  const fetchVisitsData = () => {
    axios.get(`${apiUrl}/visits/number`)
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setVisitas(response.data.data.Visits);
          setLoadingPreview(false)
        } 
      })
      .catch((error) => {
        console.error("Error fetching data: ", error.response.data.code);
        if (error.response.data.code === 401) {
          setRedirect({ trigger: true, route: '/login' })
        }
      })
  }

  const SuperC = styled('sup')({
    fontSize: '0.6em',
    verticalAlign: 'super',
  });

  const getWeather = async () => {
    try {
      const response = await axios.get(`${apiUrl}/weather/current`);
      setWeather({ temp: response.data.data.temp, icon: response.data.data.icon, fetched: true });
    } catch (error) {
      console.error(error);
    }
  };

  // Modify the existing useEffect
  React.useEffect(() => {
    setNavbarState({ showSearch: false, showOptions: false, showBack: true});
    setOrg(AuthService.getCurrentUserOrg());
    getWeather();
    fetchVisitsData();
    if (scannedCode) {
      setCode(scannedCode)
      if (qrState === "1") {
        handleVerificarPress(scannedCode)
      }
      setRedirect({ trigger: true, route: `/recepcion` });
    }

    // Set up interval to refresh weather every 30 minutes
    const weatherInterval = setInterval(() => {
      getWeather();
    }, 30 * 60 * 1000); // 30 minutes in milliseconds

    // Clean up function to clear the interval when component unmounts
    return () => clearInterval(weatherInterval);
  }, [])

  // Then fetch data every 2 minutes
  React.useEffect(() => {
    const interval = setInterval(() => {
      fetchVisitsData();
    }, 120000);
    return () => clearInterval(interval);
  }, []);

  const cardStyle = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    },
  };

  const cardStyleNoHover = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  const iconStyle = {
    position: 'absolute',
    right: -20,
    bottom: -20,
    fontSize: 105,
    color: 'rgba(0, 0, 0, 0.10)',
  };

  return (
    <Container maxWidth="sm">
        {redirect.trigger && (
            <Navigate to={redirect.route} replace={true} />
        )}
        <Box sx={{ ml: 0 }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingPreview}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={snackbar.open} autoHideDuration={5000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
              <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                {snackbar.message}
              </Alert>
            </Snackbar>
            {!loadingPreview &&
                (<Box maxWidth={true} display="flex" alignItems="center" flexDirection="column" sx={{ pt: 1 }}>
                    
                    <Grid container spacing={2}>
                      <Grid item xs={12} sx={{ mb: -3 }}>
                        <Card sx={{ bgcolor: 'white', position: 'relative', boxShadow: 0, pb: 3 }}>
                          <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box>
                              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>
                                {org}
                              </Typography>
                              <Box sx={{ display: !weather.fetched ? "flex" : "none", ml: 2, mt: 1 }}>
                                <CircularProgress size={26} />
                              </Box>
                              <Box sx={{ display: weather.fetched ? "flex" : "none", alignItems: 'center', ml: 2, mt: 1 }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', mr: 1 }}>
                                  {weather.temp}<SuperC>o</SuperC>C
                                </Typography>
                                <ReactAnimatedWeather
                                  icon={weather.icon}
                                  color='black'
                                  size={34}
                                  animate={true}
                                />
                              </Box>
                            </Box>
                            <Box
                              component="img"
                              src={`/orgs/${org.toLowerCase()}.png`}
                              alt={`${org}-logo`}
                              sx={{
                                width: '60px',
                                height: '60px',
                                objectFit: 'contain',
                              }}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ ...cardStyleNoHover, overflow: 'hidden', position: 'relative', border: 0, mt: -4 }}>
                          <CardContent sx={{ position: 'relative', zIndex: 1, textAlign: 'center' }}>
                            <Typography variant='h3' color="text.secondary">
                              {visitas}
                            </Typography>
                            <Typography variant='h5' sx={{ mb: 1 }}>
                              Visitas Nuevas Esperadas
                            </Typography>
                          </CardContent>
                          {/* <PeopleIcon sx={iconStyle} /> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ ...cardStyleNoHover, overflow: 'hidden', position: 'relative', mt: -3, pb: 4 }}>
                          <Box sx={{ position: 'relative', zIndex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1, textAlign: 'center' }}>
                              Código de Acceso
                            </Typography>
                            <VerificationInput 
                              classNames={{
                                character: "character", 
                                characterInactive: "character--inactive", 
                                characterSelected: "character--selected"
                              }} 
                              placeholder='' 
                              value={code} 
                              onChange={handleCodeInput} 
                              autoFocus={false} 
                            />
                            <Button variant="contained" sx={{ mt: 2}} size="large" color="secondary" onClick={handleVerificarPress} fullWidth>
                              Verificar
                            </Button>
                          </Box>
                          {/* <QrCodeIcon sx={iconStyle} /> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }} onClick={handleSearchPress}>
                          <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                              Buscar Visitante
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Ver lista de visitantes autorizados.
                            </Typography>
                          </CardContent>
                          <PeopleIcon sx={iconStyle} />
                        </Card>
                      </Grid>
                      <Grid item xs={widgetSizes.directorio} sx={{ display: AppConfig.haveModule('visits') ? "block" : "none" }}>
                        <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }} onClick={event => handleClick('directorio')}>
                          <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                              Directorio
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Busqueda de todos los residentes
                            </Typography>
                          </CardContent>
                          <ContactsIcon sx={iconStyle} />
                        </Card>
                      </Grid>
                      <Grid item xs={widgetSizes.directorio} sx={{ display: AppConfig.haveModule('issues') ? "block" : "none" }}>
                        <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative'}}  onClick={event => handleClick('reportes')}>
                          <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                              Reporte de Averías
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Reporta o informate de problemas en {org}
                            </Typography>
                          </CardContent>
                          <ReportIcon sx={iconStyle} />
                        </Card>
                      </Grid>
                      <Grid item xs={widgetSizes.airbnb}  sx={{ display: AppConfig.haveModule('airbnb') ? "block" : "none" }}>
                        <Card sx={{ ...cardStyle, overflow: 'hidden', position: 'relative' }} onClick={event => handleClick('airbnb')}>
                            <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Airbnb
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Gestiona el ingreso de huespedes de Airbnb
                                </Typography>
                            </CardContent>
                            <HotelIcon sx={iconStyle} />
                        </Card>
                    </Grid>
                    </Grid>
                </Box>)}
            <Dialog open={dialogIsOpen} onClose={closeDialog}>
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loadinQuery}
              >
                  <CircularProgress color="inherit" />
            </Backdrop>
              {!loadinQuery &&
                (<><DialogTitle variant='h5' sx={{ textAlign: 'center', mb: 0, mt: 3.8 }} id="dialog-title">{isInvalid ? 'Codigo Invalido' : 'Informacion del Visitante'}</DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={closeDialog}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent sx={{ px: 4}}>
                    <Typography sx={{ mt: 0, mb: 0}} variant='h6'>{dialogData ? dialogData.visitor_name : ''}</Typography>
                    <Typography sx={{ mb: 0}} variant='body1'>{dialogData ? `${AppConfig.get('unit_type')} ${dialogData.unit}` : ''}</Typography>
                    <Typography style={{fontSize: 15}} display="inline" variant='h6'>{dialogData ? dialogData.vehicle && <TextField fullWidth size="small" type='text' id='plate' sx={{ mt:3,mb: 0.5, ml: -0.5, pr: "50%" }} label="Placa" value={plates} onChange={handleVehiclePlateChange} variant="outlined" disabled={dialogData && dialogData.status == "vencido"}/> : ""}</Typography>
                    <Typography>{dialogData ? dialogData.vehicle ? 'Acceso Vehicular' : '' : ''}</Typography>
                    <Typography>{dialogData ? dialogData.walking ? 'Acceso Peatonal' : '' : ''}</Typography>
                    {isInvalid &&
                      <Box sx={{ textAlign: "center"}}>
                        <NoEncryptionGmailerrorredIcon color="error" fontSize="large"/>
                      </Box>
                    }
                     {AppConfig.getLocal("require_id_image") && !isInvalid && (
                        <>   
                        {image ? (
                        <>
                        <Box style={{ position: 'relative', marginTop: "8px" }}>
                            <ClearIcon style={{ position: 'absolute', top: '-4px', left: '-8px', cursor: 'pointer' }} onClick={() => setImage(null)} />
                            <img src={URL.createObjectURL(image)} alt="Selected Image" 
                                style={{
                                    maxWidth: '60%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                    boxShadow: '0 2px 4px rgba(0, 0, s0, 0.1)',
                                    marginTop: '8px'
                                }}
                            />
                        </Box>
                        </>
                      ):
                      (
                      <>
                      {!isInvalid && dialogData && dialogData.status !== "vencido" && (
                      <label htmlFor="contained-button-file">
                          <Button variant="text" color='secondary' component="span" startIcon={<AddAPhotoIcon />}>
                              Tomar Fotografia de ID
                          </Button>
                          <Input
                              type="file"
                              inputProps={{ accept: 'image/*', capture: 'environment'}}
                              onChange={handleIdImageChange}
                              id="contained-button-file"
                              sx={{ display: 'none' }}
                          />
                      </label>
                      )}
                      </>
                      )}
                      </>
                    )}
                    {!isInvalid && <Divider sx={{ my: 2}} />}
                    <Typography sx={{ mt: 3}}>{!isInvalid ? 'Residente:' : ''} <b>{dialogData ? dialogData.created_by_name : ''}</b></Typography>
                    {!isInvalid  &&
                    <><Box sx={{ mt: 0 }}>
                      <Typography display="inline" variant='body1'>{!isInvalid ? 'Creado ' : ''} {dialogData ? AppConfig.formatFriendlyDate(dialogData.ts) : ''}</Typography><Typography display="inline" sx={{ ml: 1 }} variant='body2'> {dialogData ? dialogData.yesterday ? ' *ayer': '' : ''}</Typography>
                    </Box>
                    </>
                    }
                    {dialogData ? dialogData.log && (<>
                    <Divider sx={{ my: 2}} />
                    <Typography sx={{ mb: 0}} variant='body1'>Usado Anteriormente</Typography>
                    
                    <Timeline position="left">
                    {dialogData ? dialogData.log && dialogData.log.reverse().map((log, index) => (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent color="text.secondary">{AppConfig.formatFriendlyDate(log.ts)}</TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        {index < dialogData.log.length - 1 && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent>{titleCase(log.to)}</TimelineContent>
                    </TimelineItem>
                    )): ''}
                    </Timeline>
                    </>
                    ) : ''}

                                      
                </DialogContent>
                <DialogActions sx={{mb: 2}}>
                    <Button variant="outlined" onClick={closeDialog}>Cancelar</Button>
                    {!isInvalid && dialogData && dialogData.status !== "vencido" && (
                      <Button onClick={() => expireCode()} color="secondary" variant="contained">
                        Confirmar Acceso
                      </Button>
                    )}
                    {!isInvalid && dialogData && dialogData.status === "vencido" && (
                      <Button color="secondary" variant="contained" disabled>
                        Confirmar Acceso
                      </Button>
                    )}
                </DialogActions></>)
              }
                
            </Dialog>
            <Dialog fullScreen open={successDialog} onClose={closeSuccessDialog} TransitionComponent={Transition} keepMounted> 
            <IconButton
                  aria-label="close"
                  onClick={closeSuccessDialog}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              <DialogContent sx={{ backgroundColor: "#588157"}}>
                  <Box maxWidth="sm" display="flex" alignItems="center" justifyContent="center" sx={{ margin: 'auto', mt: 2 }}>
                    <Box sx={{ textAlign: "center", mt: "40vh"}}>
                          <DoneOutlineIcon
                            style={{ 
                              fontSize: 80,
                              color: "white",
                            }}
                          />
                          <Typography variant="h5"
                            style={{ 
                              color: "white"
                            }}
                          >
                            Acceso Otorgado
                          </Typography>
                    </Box>
                  </Box>
              </DialogContent>

            </Dialog>
            {dialogSearchIsOpen ? (<Dialog fullScreen open={dialogSearchIsOpen} onClose={closeSearchDialog}>
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loadinQuery}
              >
                  <CircularProgress color="inherit" />
            </Backdrop>
              {!loadinQuery &&
                (<>
                <IconButton
                  aria-label="close"
                  onClick={closeSearchDialog}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent sx={{ backgroundColor: "#EFEEE8"}}>
                  <Box maxWidth="sm" display="flex" alignItems="center" justifyContent="center" sx={{ margin: 'auto', mt: 2 }}>
                  <TextField 
                    fullWidth 
                    size="medium" 
                    id='search' 
                    sx={{ textAlign: 'center', mb: 0, mt: 2 }} 
                    label="Buscar Visitante" 
                    variant="outlined" 
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                            <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                    />
                    
                  </Box>
                  <Box maxWidth="sm" display="flex" alignItems="center" justifyContent="center" sx={{ margin: 'auto', mt: 2 }}>
                    <Grid container spacing={2}>
                        {data.active ? renderData("active") : ''}  
                    </Grid>
                  </Box>
                  {data.used.length > 0 && (
                    <>
                      <Box maxWidth="sm" display="flex" alignItems="center" justifyContent="left" sx={{ margin: 'auto', mt: 2 }}>
                        <Chip label="Usados" variant="outlined" />
                      </Box>
                      <Box maxWidth="sm" display="flex" alignItems="center" justifyContent="center" sx={{ margin: 'auto', mt: 2 }}>
                        <Grid container spacing={2}>
                        {data ? renderData("used") : ''} 
                        </Grid>
                      </Box>
                    </>
                  )}
                  
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={closeSearchDialog}>Cerrar</Button>
                </DialogActions></>)
              }
                
            </Dialog>) : ''}
            <Fab color="secondary" aria-label="add" sx={fabStyle} variant="extended" onClick={() => setRedirect({ trigger: true, route: '/qr' })}>
              <QrCodeScannerIcon sx={{mr: 1}}/>
              Escanear QR
            </Fab>
        </Box>
    <BackgroundImage />
    </Container>
);
}