import AppConfig from '../services/app-config';

const getLocaleStrings = (formConfig) => ({
  "es": {
    "langpick": "Selecciona un Idioma",
    "next": "Siguiente",
    "cancel": "Cancelar",
    "button_accept_rules": "Aceptar",
    "button_input_data": "Enviar",
    "lang": "Idioma",
    "lang_name": "Español",
    "intro": `Llena el siguiente formulario y acepta el reglamento requerido para completar los datos de tu estadia en ${AppConfig.titleCase(formConfig.org)}.`,
    "flag": "ESP",
    "helper_init": "Continue para completar el formulario",
    "form_fetch_error": "Hubo un error al intentar obtener el formulario, porfavor intente mas tarde.",
    "helper_accept_rules": "Presione aceptar si esta deacuerdo",
    "back": "Atras",
    "required_fields": "Todos los campos con '*' son requeridos",
    "proccessing_error": "Hubo un error al procesar la informacion",
    "field_completed": "Campo Completado",
    "form_completed": "El formulario ha sido completado correctamente, muchas gracias.",
    "already_accepted": "Ya aceptaste este reglamento",
    "review_rules": "Revisar Reglamento",
    "access_code": `Utiliza este codigo de acceso en recepcion de ${AppConfig.titleCase(formConfig.org)}`
  },
  "en": {
    "langpick": "Pick a Language",
    "next": "Next",
    "cancel": "Cancel",
    "button_accept_rules": "Accept",
    "button_input_data": "Submit",
    "lang": "Language",
    "lang_name": "English",
    "intro": `Fill out the following form and accept the required regulation to complete your stay at ${AppConfig.titleCase(formConfig.org)}.`,
    "flag": "GB",
    "helper_init": "Continue to complete the form",
    "form_fetch_error": "There was an error fetching the form, please try again later.",
    "helper_accept_rules": "Press accept if you agree",
    "back": "Back",
    "required_fields": "All fields with '*' are required",
    "proccessing_error": "There was an error processing the information",
    "field_completed": "Field Completed",
    "form_completed": "The form has been completed successfully, thank you.",
    "already_accepted": "You already accepted this term",
    "review_rules": "Review Code of Conduct",
    "access_code": `Use this access code in reception of ${AppConfig.titleCase(formConfig.org)}`
  },
  "pt": {
    "langpick": "Escolha um Idioma",
    "next": "Próximo",
    "cancel": "Cancelar",
    "button_accept_rules": "Aceitar",
    "button_input_data": "Enviar",
    "lang": "Idioma",
    "lang_name": "Português",
    "intro": `Preencha o formulário a seguir e aceite o Regulamento requerido para completar os dados de sua estadia em ${AppConfig.titleCase(formConfig.org)}.`,
    "flag": "PT",
    "helper_init": "Continuar para completar o formulário",
    "form_fetch_error": "Ocorreu um erro ao tentar obter o formulário, por favor tente novamente mais tarde.",
    "helper_accept_rules": "Pressione aceitar se você concorda",
    "back": "Voltar",
    "required_fields": "Todos os campos com '*' são requeridos",
    "proccessing_error": "Ocorreu um erro ao processar a informação",
    "field_completed": "Campo Completado",
    "form_completed": "O formulário foi completado com sucesso, obrigado.",
    "already_accepted": "Você já aceitou este termo",
    "review_rules": "Revisar Regulamento",
    "access_code": `Utilize este código de acesso na recepção de ${AppConfig.titleCase(formConfig.org)}`
  },
  "fr": {
    "langpick": "Choisissez une langue",
    "next": "Suivant",
    "cancel": "Annuler",
    "button_accept_rules": "Accepter",
    "button_input_data": "Soumettre",
    "lang": "Langue",
    "lang_name": "Français",
    "intro": `Remplissez le formulaire ci-dessous et acceptez le règlement requis pour compléter les données de votre séjour à ${AppConfig.titleCase(formConfig.org)}.`,
    "flag": "FR",
    "helper_init": "Continuer pour compléter le formulaire",
    "form_fetch_error": "Une erreur s'est produite lors de la récupération du formulaire, veuillez réessayer plus tard.",
    "helper_accept_rules": "Appuyez sur accepter si vous acceptez",
    "back": "Précédent",
    "required_fields": "Tous les champs avec '*' sont obligatoires",
    "proccessing_error": "Une erreur s'est produite lors du traitement de l'information",
    "field_completed": "Champ Completé",
    "form_completed": "Le formulaire a été complété avec succès, merci.",
    "already_accepted": "Vous avez déjà accepté ce terme",
    "review_rules": "Revoir Reglement",
    "access_code": `Utilisez ce code d'accès à la réception de ${AppConfig.titleCase(formConfig.org)}`
  },
  "de": {
    "langpick": "Wähle eine Sprache",
    "next": "Weiter",
    "cancel": "Abbrechen",
    "button_accept_rules": "Akzeptieren",
    "button_input_data": "Absenden",
    "lang": "Sprache",
    "lang_name": "Deutsch",
    "intro": `Füllen Sie das folgende Formular aus und akzeptieren Sie die erforderliche Vorschrift, um Ihre Aufenthaltsdaten bei ${AppConfig.titleCase(formConfig.org)} zu erfassen.`,
    "flag": "DE",
    "helper_init": "Weiter zum Befüllen des Formulars",
    "form_fetch_error": "Beim Laden des Formulars ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
    "helper_accept_rules": "Drücke auf akzeptieren, wenn Sie zustimmen",
    "back": "Zurück",
    "required_fields": "Alle Felder mit '*' sind obligatorisch",
    "proccessing_error": "Beim Verarbeiten der Informationen ist ein Fehler aufgetreten",
    "field_completed": "Feld abgeschlossen",
    "form_completed": "Formular erfolgreich ausgefüllt, danke.",
    "already_accepted": "Sie haben bereits diesen Begriff akzeptiert",
    "review_rules": "Regelung prüfen",
    "access_code": `Utilisez ce code d'accès à la réception de ${AppConfig.titleCase(formConfig.org)}`
  }
});

export default getLocaleStrings;
