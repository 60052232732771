import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { TypeAnimation } from 'react-type-animation';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import VerifiedIcon from '@mui/icons-material/Verified';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { Button, Box, Input, CircularProgress } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import BackgroundImage from '../components/Cityline';

import AuthService from '../services/auth';
import AppConfig from '../services/app-config';

import { Navigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';

import FormInput from '../components/FormInput';
import AcceptRules from '../components/AcceptRules';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import getLocaleStrings from '../services/locales'; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import KeyIcon from '@mui/icons-material/Key';
import axios from 'axios';

function FormCard({ setNavbarState, navbarState}) {
    const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
    const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const [openReglamento, setOpenReglamento] = React.useState(false);
    const [rules, setRules] = React.useState({rules: [], loading: true});
    const [formConfig, setFormConfig] = React.useState({
        org: "",
        language: "es",
        languages: ["es"],
        current_step: null,
        completed: false,
      });
    const [loaded,setLoaded] = React.useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [localeStrings, setLocaleStrings] = useState({});
    const [notFound, setNotFound] = useState(false);

    const apiUrl = process.env.REACT_APP_MGO_API_URL;
    const formid = new URLSearchParams(location.search).get('id');

    const userCors = process.env.REACT_APP_USE_CORS;
    if ( userCors === "true") {
        axios.defaults.withCredentials = true
    }


    let button_icons = {
        "init": <NavigateNextIcon />,
        "input_data": <FileUploadIcon />,
        "cancel": <ClearIcon />,
        "accept_rules": <CheckCircleOutlineIcon />
    }

    const fetchRules = () => {
        setShowBackdrop(true);
        axios.get(`${apiUrl}/form/rules/${formConfig.steps.guest_rules.accept}`)
        .then((response) => {
            let response_data = response.data.data
            setRules({rules: response_data[formConfig.language].rules, loading: false});
            setShowBackdrop(false);
            setOpenReglamento(true);
        })
    }

    const handleCloseReglamento = () => setOpenReglamento(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbar({ ...snackbar, open: false });
      };

    const completedCard = loaded && (
        <Box sx={{ textAlign: 'center', alignItems: 'center', mb: 4 }}>
            <TypeAnimation
                sequence={[
                    'Gracias',
                    2000,
                    'Thanks!',
                    2000,
                    'Merci',
                    1500,
                    'Obrigado',
                    1500,
                    'Danke',
                    1500,
                    
                ]}
                speed={50}
                style={{ fontSize: '2em' }}
                repeat={Infinity}
                />
            <Typography variant="h3" color="textPrimary" textTransform={ 'uppercase'}>
                {formConfig.org}
            </Typography>
            <Box
                  component="img"
                  src={`/orgs/${formConfig.org.toLowerCase()}.png`}
                  alt={`${formConfig.org}-logo`}
                  sx={{
                    width: '60px',
                    height: '60px',
                    objectFit: 'contain',
                  }}
                />
            <Card sx={{ mt: 4, px: 4}} variant="outlined">
                <CardContent>
                    <VerifiedIcon sx={{mt: 2, fontSize: '2rem', alignSelf: 'center'}} color='secondary'/>
                    <Typography variant="h6" color="textSecondary" sx={{mt: 4, display: 'flex', alignItems: 'flex-start'}}>
                        {localeStrings[formConfig.language].form_completed}
                    </Typography>
                    <Box sx={{p: 4, border: 1, borderColor: 'textSecondary', borderRadius: '10px', mt: 4}}>
                        <Typography variant="h6" color="textSecondary" sx={{display: 'flex', alignItems: 'flex-start'}}>
                            {localeStrings[formConfig.language].access_code}
                        </Typography>
                        <Divider/>
                        <Typography variant="h5" color="secondary" sx={{mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold'}}>
                            <KeyIcon sx={{mr: 1}} />
                            {formConfig.visit_code}
                        </Typography>
                    </Box>
                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        startIcon={<ReadMoreIcon />}
                        sx={{ my: 4 }}
                        onClick={() => fetchRules()}
                    >
                        {localeStrings[formConfig.language].review_rules}
                    </Button>
                    
                </CardContent>
            </Card>
        </Box>
    )

    const welcomeCard = loaded && (
        <Box sx={{ textAlign: 'center', alignItems: 'center', mb: 4 }}>
            <TypeAnimation
                sequence={[
                    // Same substring at the start will only be typed once, initially
                    'Bienvenido a',
                    2000,
                    'Welcome to',
                    2000,
                    'Bienvenue Ã ',
                    1500,
                    'Benvenuto a',
                    1500,
                    'Willkommen bei',
                    1500,
                    
                ]}
                speed={50}
                style={{ fontSize: '2em' }}
                repeat={Infinity}
                />
            <Typography variant="h3" color="textPrimary" textTransform={ 'uppercase'}>
                {formConfig.org}
            </Typography>
            <Box
                  component="img"
                  src={`/orgs/${formConfig.org.toLowerCase()}.png`}
                  alt={`${formConfig.org}-logo`}
                  sx={{
                    width: '60px',
                    height: '60px',
                    objectFit: 'contain',
                  }}
                />
            <Card sx={{ mt: 4, px: 4}} variant="outlined">
                <CardContent>
                    <Typography variant="body1" color="textSecondary" sx={{mt: 2}}>
                        {localeStrings[formConfig.language].langpick}
                    </Typography>
                    <FormControl fullWidth variant="outlined" sx={{mt: 2}}>
                        <InputLabel id="language-select-label">{localeStrings[formConfig.language].lang}</InputLabel>
                        <Select
                            labelId="language-select-label"
                            id="language-select"
                            label={localeStrings[formConfig.language].lang}
                            onChange={e => {
                                setFormConfig({...formConfig, language: e.target.value, steps: {...formConfig.steps, [formConfig.current_step]: {...formConfig.steps[formConfig.current_step], language: e.target.value }}})
                            }}
                            value={formConfig.language}
                        >
                            {formConfig.languages.map((value) => (
                              <MenuItem key={value} value={value}>
                                {getUnicodeFlagIcon(localeStrings[value].flag)} {AppConfig.titleCase(localeStrings[value].lang_name)}
                              </MenuItem>
                            ))}
                            
                            
                        </Select>
                    </FormControl>
                    <Typography variant="h6" color="textSecondary" sx={{mt: 5, display: 'flex', alignItems: 'flex-start'}}>
                        {localeStrings[formConfig.language].intro}
                    </Typography>
                    <VerifiedIcon sx={{my: 2, fontSize: '2rem', alignSelf: 'center'}} color='secondary'/>
                </CardContent>
            </Card>
        </Box>   
    )



    const handleSubmit = (e) => {
        e.preventDefault();
        //verify fields
        setShowBackdrop(true);
        let current_step_data = formConfig.steps[formConfig.current_step];
        if (current_step_data['type'] === "input_data") {
            //loop through data dictionary in `current_step_data.data`
            let all_fields_ok = true;
            for (let [key, value] of Object.entries(current_step_data.data)) {
                if (!value.submitted && value.required && (!value.value || value.value.trim() === "")) {
                    all_fields_ok = false;
                    setSnackbar({ open: true, message: `${localeStrings[formConfig.language].required_fields} '${value.label[formConfig.language]}'.`, severity: 'error' });
                    break;
                }
                
            }
            if (!all_fields_ok) {
                setShowBackdrop(false);
                return;
            }

        }
        console.log(formConfig.steps[formConfig.current_step])
        axios.post(`${apiUrl}/formdriver/${formid}`, {stepid: formConfig.current_step, stepdata: current_step_data})
            .then((response) => {
                AppConfig.parseResponse(response);
                if (response.status === 200) {
                    fetchFormConfig()
                  } else if (response.status === 422) {
                    setSnackbar({ open: true, message: response.data.data.mensaje, severity: 'error' });
                  }
                setShowBackdrop(false);
            })
            .catch((error) => {
                if(error.response && error.response.data && error.response.data.code === 422) {
                setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
                }
                else  {
                console.error('Error commenting:', error);
                setSnackbar({ open: true, message: localeStrings[formConfig.language].proccessing_error, severity: 'error' })};
                setShowBackdrop(false);
            });
    };

    const handleBack = (e) => {
        e.preventDefault();
        //verify fields
        setShowBackdrop(true);
        axios.post(`${apiUrl}/formdriver/${formid}`, {stepid: formConfig.current_step, stepdata: formConfig, stepaction: "back"})
            .then((response) => {
                AppConfig.parseResponse(response);
                if (response.status === 200) {
                    fetchFormConfig()
                  } else if (response.status === 422) {
                    setSnackbar({ open: true, message: response.data.data.mensaje, severity: 'error' });
                  }
                setShowBackdrop(false);
            })
            .catch((error) => {
                if(error.response && error.response.data && error.response.data.code === 422) {
                setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
                }
                else  {
                console.error('Error commenting:', error);
                setSnackbar({ open: true, message: localeStrings[formConfig.language].proccessing_error, severity: 'error' })};
                setShowBackdrop(false);
            });
    };

    const fetchFormConfig = () => {
        setShowBackdrop(true);
        setNotFound(false);
        axios.get(`${apiUrl}/formdriver/${formid}`)
        .then((response) => {
            AppConfig.parseResponse(response);
            if (response.status === 200) {
                let response_data = response.data.data
                setFormConfig(response_data);
                let delay = response_data.steps[response_data.current_step].cta_delay;
                if (response_data.steps[response_data.current_step].type === "accept_rules" &&
                    response_data.steps[response_data.current_step].accepted[response_data.steps[response_data.current_step].rule_index]) {
                        delay = 0
                    }
                setLocaleStrings(getLocaleStrings(response_data))
                console.log(response_data)
                setLoaded(true)
                if (response_data.login_required) {
                    if (!AuthService.isLogged()) {
                        setRedirect({ trigger: true, route: '/login' });
                    }
                }
                if (delay) {
                    setButtonDisabled(true);
                    // Disable button for specified delay
                    const timer = setTimeout(() => {
                        setButtonDisabled(false);
                    }, delay);
        
                    // Cleanup timer on component unmount
                    return () => clearTimeout(timer);
                }
            }
            
        })
        .catch((error) => {
            console.error('Error fetching form config:', error);
            if (error.response && error.response.status === 404) {
                setNotFound(true);
            } else {
                setSnackbar({ open: true, message: localeStrings[formConfig.language].form_fetch_error, severity: 'error' });
            }
        })
        .finally(() => {
            setShowBackdrop(false);
        })
    }

    React.useEffect(() => {
        setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false});
        fetchFormConfig();
    },[]);

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            {notFound ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 6,
                        border: '1px solid #e0e0e0',
                        borderRadius: 2,
                        bgcolor: '#fff',
                        textAlign: 'center'
                    }}
                >
                    <Typography variant="h3" component="h2" color="text.secondary" sx={{my: 2}}>
                        404
                    </Typography>
                    <Typography variant="h4" component="h2" sx={{my: 2}}>
                        Formulario no encontrado
                    </Typography>
                    <Box sx={{ mt: 4}}>
                        <img src="/logo.svg" alt="Logo" style={{ width: '100%', minWidth: 50, maxWidth: 100 }} />
                    </Box>
                    <Typography variant="body2"color="text.secondary" sx={{mb: 2}}>
                        MiGarita.Online
                    </Typography>
                </Box>
            ) : (
                <Container maxWidth="sm">
                {loaded && (     
                <Box>
                    {redirect.trigger && (
                        <Navigate to={redirect.route} replace={true} />
                    )}
                    <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
                        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                        {snackbar.message}
                        </Alert>
                    </Snackbar>
                    <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Box
                        sx={{ mt: 2 ,py: 0.5, px: 0.5, display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: "550px" }}
                    >
                        
                        {(() => {
                            switch (formConfig.steps[formConfig.current_step]?.type || formConfig.current_step) {
                            case "init":
                                return welcomeCard;
                            case "completed":
                                return completedCard;
                            case "accept_rules":
                                return <AcceptRules formConfig={formConfig} localeStrings={localeStrings} axios={axios}/>;
                            case "input_data":
                                return <FormInput formConfig={formConfig} localeStrings={localeStrings} setFormConfig={setFormConfig} axios={axios}/>;
                            default:
                                return welcomeCard;
                            }
                        })()}
                        {!formConfig.completed && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 6, py: 0.5, px: 0.5 }}>
                            {!isButtonDisabled && <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                                {localeStrings[formConfig.language][`helper_${formConfig.steps[formConfig.current_step].type}`]}
                            </Typography>}
                            <Button 
                                variant="contained" 
                                fullWidth
                                color="secondary"
                                size="large"
                                onClick={handleSubmit}
                                disabled={isButtonDisabled}
                                endIcon={!isButtonDisabled && button_icons[formConfig.steps[formConfig.current_step].type]}>
                                {isButtonDisabled ? (
                                    <>
                                        <CircularProgress size={24} sx={{ color: "gray"}}/>
                                    </>
                                ) : (
                                    formConfig.steps[formConfig.current_step].type === "accept_rules" && 
                                    formConfig.steps[formConfig.current_step].accepted[formConfig.steps[formConfig.current_step].rule_index] 
                                    ? localeStrings[formConfig.language].next
                                    : localeStrings[formConfig.language][`button_${formConfig.steps[formConfig.current_step].type}`]
                                      ? localeStrings[formConfig.language][`button_${formConfig.steps[formConfig.current_step].type}`]
                                      : localeStrings[formConfig.language].next
                                )}
                            </Button>
                            {formConfig.steps[formConfig.current_step].show_back && <Button 
                                variant="text" 
                                fullWidth
                                size="large"
                                onClick={handleBack}
                                startIcon={<ArrowBackIosIcon/>}
                                sx={{mt: 2}}>
                                {localeStrings[formConfig.language].back}
                            </Button>}
                            
                        </Box>
                        )}
                        
                    </Box>
                    
                </Box>
                )}
                <BackgroundImage />
                </Container>
            )}
            <Dialog
                open={openReglamento}
                onClose={handleCloseReglamento}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Reglamento</DialogTitle>
                <DialogContent>
                    <List>
                        {!rules.loading && rules.rules.map((rule, index) => (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <ListItemText primary={rule} />
                                </ListItem>
                                {index < rules.rules.length - 1 && (
                                    <Divider component="li" />
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReglamento}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default FormCard;
